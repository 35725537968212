import { useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import useSWRMutation from "swr/mutation";

import S3Service from "Services/S3Service";
import { API } from "constants.js";
import { sendRequest } from "utils.js";

export const useFiles = () => {
  const { keycloak } = useKeycloak();

  const { trigger: createFile, isMutating: isSaving } = useSWRMutation(
    API.FILE,
    (path, { arg }) =>
      sendRequest(path, {
        ...arg,
        method: "POST",
        accessToken: keycloak.token,
      })
  );

  const { trigger: updateFile, isMutating: isUpdating } = useSWRMutation(
    API.FILE,
    (path, { arg }) =>
      sendRequest(`${path}/${arg.fileId}`, {
        ...arg,
        method: "PUT",
        accessToken: keycloak.token,
      })
  );

  return {
    createFile,
    updateFile,
    isSaving,
    isUpdating,
  };
};

export const useUploadFiles = (createFile, updateFile) => {
  const [files, setFiles] = useState({});

  const dropFile = (acceptedFiles, category) => {
    if (!acceptedFiles?.length) {
      return;
    }

    setFiles((prevFiles) => ({ ...prevFiles, [category]: acceptedFiles[0] }));
  };

  const deleteFile = (category) => {
    setFiles((prevFiles) => ({ ...prevFiles, [category]: null }));
  };

  const uploadFile = async ({ file, fileId, entity, category, isPublic }) => {
    const { name, size, type: contentType } = file;
    const body = {
      entity,
      category,
      contentType,
      name,
      size,
      isPublic,
    };

    let uploadedFile;

    if (fileId) {
      uploadedFile = await updateFile({ fileId, body });
    } else {
      uploadedFile = await createFile({ body });
    }

    await S3Service.uploadFileToS3(uploadedFile.uploadUrl, file);

    return uploadedFile._id;
  };

  return {
    files,
    setFiles,
    dropFile,
    deleteFile,
    uploadFile,
  };
};
