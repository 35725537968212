import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import DatePicker from "react-datepicker";
import { Controller } from "react-hook-form";

import DocumentItem from "Components/Common/DocumentItem";
import DragDrop from "Components/Common/DragDrop";
import FormControlErrorMessage from "Components/Common/FormControlErrorMessage";
import { ACHIEVEMENT_FILE_CATEGORIES, SUBMIT_TYPES } from "constants.js";

const AchievementForm = (props) => {
  const {
    submitType,
    files,
    formProps: { register, control, errors },
    disabled = false,
    onDropFile,
    onDeleteFile,
  } = props;

  const attendeesFile = files[ACHIEVEMENT_FILE_CATEGORIES.ATTENDEES];
  const certificateFile = files[ACHIEVEMENT_FILE_CATEGORIES.CERTIFICATE];
  const badgeFile = files[ACHIEVEMENT_FILE_CATEGORIES.BADGE];

  return (
    <>
      <Form.Group controlId="name" className="form-group required">
        <Form.Label>Achievement title</Form.Label>
        <Form.Control
          type="text"
          name="name"
          disabled={disabled}
          {...register("name", { required: true })}
          isInvalid={!!errors.name}
        />
        <FormControlErrorMessage
          show={!!errors.name}
          message="Achievement title is required"
        />
      </Form.Group>

      <Form.Group controlId="message" className="form-group required">
        <Form.Label>Achievement message</Form.Label>
        <Form.Control
          type="text"
          name="message"
          as="textarea"
          rows={3}
          className="fs-13 h-100"
          disabled={disabled}
          {...register("message", {
            required: submitType === SUBMIT_TYPES.PREVIEW,
          })}
          isInvalid={!!errors.message}
        />
        <FormControlErrorMessage
          show={!!errors.message}
          message="Achievement message is required"
        />
      </Form.Group>

      <Row>
        <Form.Group
          as={Col}
          controlId="earnedDate"
          className="form-group required"
          sm={6}
        >
          <Form.Label id="earnedDate">Achieved date</Form.Label>
          <Controller
            name="earnedDate"
            control={control}
            rules={{ required: submitType === SUBMIT_TYPES.PREVIEW }}
            defaultValue={null}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                showPopperArrow={false}
                ariaLabelledBy="earnedDate"
                dateFormat="MMM dd, yyyy"
                className={`form-control ${errors.earnedDate ? "is-invalid" : ""}`}
                selected={value}
                disabled={disabled}
                onChange={onChange}
              />
            )}
          />
          <FormControlErrorMessage
            show={errors.earnedDate?.type === "required"}
            message="Achieved date is required"
          />
        </Form.Group>

        <Form.Group
          as={Col}
          controlId="audience"
          className="form-group required"
        >
          <Form.Label>Audience</Form.Label>
          <Form.Control
            type="text"
            name="audience"
            disabled={disabled}
            {...register("audience", {
              required: submitType === SUBMIT_TYPES.PREVIEW,
            })}
            isInvalid={!!errors.audience}
          />
          <FormControlErrorMessage
            show={!!errors.audience}
            message="Audience is required"
          />
        </Form.Group>
      </Row>

      <Form.Group controlId="attendees" className="form-group required">
        <Form.Label id="attendees">
          Attendees list (List of individuals who will receive the specified
          achievement)
        </Form.Label>
        <Form.Control
          type="hidden"
          name={ACHIEVEMENT_FILE_CATEGORIES.ATTENDEES}
          {...register(ACHIEVEMENT_FILE_CATEGORIES.ATTENDEES, {
            required: submitType === SUBMIT_TYPES.PREVIEW,
            validate: () =>
              submitType !== SUBMIT_TYPES.PREVIEW ||
              (submitType === SUBMIT_TYPES.PREVIEW && !!attendeesFile),
          })}
        />

        {attendeesFile ? (
          <DocumentItem
            document={attendeesFile}
            isSaving={disabled}
            onDelete={() =>
              onDeleteFile(
                ACHIEVEMENT_FILE_CATEGORIES.ATTENDEES,
                submitType === SUBMIT_TYPES.PREVIEW
              )
            }
          />
        ) : (
          <DragDrop
            allowedExtensions={["csv"]}
            isRequired={submitType === SUBMIT_TYPES.PREVIEW}
            onDrop={(newFiles) =>
              onDropFile(newFiles, ACHIEVEMENT_FILE_CATEGORIES.ATTENDEES)
            }
          />
        )}

        <FormControlErrorMessage
          show={!!errors[ACHIEVEMENT_FILE_CATEGORIES.ATTENDEES]}
          message="Attendees list file is required"
        />
      </Form.Group>

      <Form.Group controlId="certificate" className="form-group">
        <Form.Label id="certificate">
          Certificate image (Background of the certificate with predefined copy)
        </Form.Label>
        <Form.Control
          type="hidden"
          name={ACHIEVEMENT_FILE_CATEGORIES.CERTIFICATE}
          {...register(ACHIEVEMENT_FILE_CATEGORIES.CERTIFICATE, {
            required: submitType === SUBMIT_TYPES.PREVIEW && !badgeFile,
            validate: () =>
              submitType !== SUBMIT_TYPES.PREVIEW ||
              (submitType === SUBMIT_TYPES.PREVIEW &&
                (!!certificateFile || !!badgeFile)),
          })}
        />

        {certificateFile ? (
          <DocumentItem
            document={certificateFile}
            isSaving={disabled}
            onDelete={() =>
              onDeleteFile(
                ACHIEVEMENT_FILE_CATEGORIES.CERTIFICATE,
                submitType === SUBMIT_TYPES.PREVIEW && !badgeFile
              )
            }
          />
        ) : (
          <DragDrop
            allowedExtensions={["png"]}
            isRequired={submitType === SUBMIT_TYPES.PREVIEW && !badgeFile}
            onDrop={(newFiles) =>
              onDropFile(newFiles, ACHIEVEMENT_FILE_CATEGORIES.CERTIFICATE)
            }
          />
        )}

        <FormControlErrorMessage
          show={!!errors[ACHIEVEMENT_FILE_CATEGORIES.CERTIFICATE]}
          message="Certificate image is required"
        />
      </Form.Group>
      <Form.Group controlId="badge" className="form-group">
        <Form.Label id="badge">
          Badge image (Optional achievement for specif milestones)
        </Form.Label>
        <Form.Control
          type="hidden"
          name={ACHIEVEMENT_FILE_CATEGORIES.BADGE}
          {...register(ACHIEVEMENT_FILE_CATEGORIES.BADGE, {
            required: submitType === SUBMIT_TYPES.PREVIEW && !certificateFile,
            validate: () =>
              submitType !== SUBMIT_TYPES.PREVIEW ||
              (submitType === SUBMIT_TYPES.PREVIEW &&
                (!!certificateFile || !!badgeFile)),
          })}
        />

        {badgeFile ? (
          <DocumentItem
            document={badgeFile}
            isSaving={disabled}
            onDelete={() =>
              onDeleteFile(
                ACHIEVEMENT_FILE_CATEGORIES.BADGE,
                submitType === SUBMIT_TYPES.PREVIEW && !certificateFile
              )
            }
          />
        ) : (
          <DragDrop
            allowedExtensions={["png"]}
            maxSize={0.25}
            isRequired={submitType === SUBMIT_TYPES.PREVIEW && !certificateFile}
            onDrop={(newFiles) =>
              onDropFile(newFiles, ACHIEVEMENT_FILE_CATEGORIES.BADGE)
            }
          />
        )}

        <FormControlErrorMessage
          show={!!errors[ACHIEVEMENT_FILE_CATEGORIES.BADGE]}
          message="Badge image is required"
        />
      </Form.Group>
    </>
  );
};

AchievementForm.propTypes = {
  submitType: PropTypes.oneOf(Object.values(SUBMIT_TYPES)).isRequired,
  files: PropTypes.object.isRequired,
  formProps: PropTypes.shape({
    register: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
  }),
  disabled: PropTypes.bool,
  onDropFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
};

export default AchievementForm;
