import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowRight,
  faCheckCircle,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileWord,
  faPen,
  faTag,
  faTrashCan,
  faUsers,
  faUserXmark,
} from "@fortawesome/free-solid-svg-icons";
import { SourceEnum } from "@micoach/js-sdk";

const ASSETS_BUCKET = "https://micoach-prod-bucket.s3.amazonaws.com/assets";

export const EMAIL_PATTERN =
  /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PERCENTAGE_PATTERN = /^0*(?:[0-9]{1,2}?|100)$/;

export const PHONE_NUMBER_PATTERN = /^\d{10}$/;

export const URL_PATTERN =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
export const URL_HTTP_PATTERN =
  /^(https?:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const assets = {
  HOME_MICOACH: `${ASSETS_BUCKET}/landing`,
  ZERO_STATE: `${ASSETS_BUCKET}/zero-state.png`,
  REFERRAL_ZERO_STATE: `${ASSETS_BUCKET}/referral-zero-state`,
  THANKS: `${ASSETS_BUCKET}/thanks.png`,
  GENERIC_ERROR: `${ASSETS_BUCKET}/generic-error.png`,
  TRAINING: `${ASSETS_BUCKET}/training.jpg`,
  ADMIN_EMAIL_TEMPLATE: `${ASSETS_BUCKET}/email-template.svg`,
  ADMIN_SKILL: `${ASSETS_BUCKET}/skill-catalog.svg`,
  ADMIN_ROLE: `${ASSETS_BUCKET}/role-catalog.svg`,
  ADMIN_TAG: `${ASSETS_BUCKET}/tag-catalog.svg`,
  ADMIN_ACHIEVEMENT: `${ASSETS_BUCKET}/achievements-catalog.svg`,
  NO_EVALUATIONS: `${ASSETS_BUCKET}/no-files.png`,
  jobCategories: {
    OTHER: `${ASSETS_BUCKET}/job-category-other`,
  },
  WELCOME: `${ASSETS_BUCKET}/welcome.svg`,
  PROSPECTS_ZERO_STATE: `${ASSETS_BUCKET}/prospects-zero-state`,
  MICOACH_LOGO: `${ASSETS_BUCKET}/micoach-logo.svg`,
};

export const kanbanColumns = {
  BLOCKED: {
    key: "BLOCKED",
    label: "Do not contact",
    isStep: false,
  },
  LEADS: {
    key: "LEADS",
    label: "Leads",
    isStep: false,
  },
  PIPELINE: {
    key: "PIPELINE",
    label: "Pipeline",
    isStep: true,
  },
  RESUME_REVIEW: {
    key: "RESUME_REVIEW",
    label: "Resume Review",
    isStep: true,
  },
  PRE_SCREEN: {
    key: "PRE_SCREEN",
    label: "TA Interview",
    isStep: true,
  },
  CODE_TEST: {
    key: "CODE_TEST",
    label: "Coding Challenge",
    isStep: true,
  },
  TECHNICAL_INTERVIEW: {
    key: "TECHNICAL_INTERVIEW",
    label: "Technical Interview (Customer)",
    isStep: true,
  },
  OFFER_PROCESS: {
    key: "OFFER_PROCESS",
    label: "Offer process",
    isStep: true,
  },
  OFFER_ACCEPTED: {
    key: "OFFER_ACCEPTED",
    label: "Offer Accepted",
    isStep: true,
  },
  IMMIGRATION_PROCESS: {
    key: "IMMIGRATION_PROCESS",
    label: "Immigration Process",
    isStep: true,
  },
  HIRED: {
    key: "HIRED",
    label: "Hired",
    isStep: true,
  },
};

export const applicationProcessSteps = [
  {
    value: "BLOCKED",
    label: "Do not contact",
  },
  {
    value: "LEADS",
    label: "Leads",
  },
  {
    value: "PIPELINE",
    label: "Pipeline",
  },
  {
    value: "RESUME_REVIEW",
    label: "Resume Review",
  },
  {
    value: "PRE_SCREEN",
    label: "TA Interview",
  },
  {
    value: "CODE_TEST",
    label: "Coding Challenge",
  },
  {
    value: "TECHNICAL_INTERVIEW",
    label: "Technical Interview (Customer)",
  },
  {
    value: "OFFER_PROCESS",
    label: "Offer Process",
  },
  {
    value: "OFFER_ACCEPTED",
    label: "Offer Accepted",
  },
  {
    value: "IMMIGRATION_PROCESS",
    label: "Immigration Process",
  },
  {
    value: "HIRED",
    label: "Hired",
  },
  {
    value: "DISCARDED",
    label: "Rejected",
  },
];

export const candidateStatus = {
  BLOCKED: {
    key: "BLOCKED",
  },
  LEAD: {
    key: "LEAD",
  },
  PIPELINE: {
    key: "PIPELINE",
  },
  RESUME_REVIEW: {
    key: "RESUME_REVIEW",
  },
  PRE_SCREEN: {
    key: "PRE_SCREEN",
  },
  CODE_TEST: {
    key: "CODE_TEST",
  },
  TECHNICAL_INTERVIEW: {
    key: "TECHNICAL_INTERVIEW",
  },
  OFFER_PROCESS: {
    key: "OFFER_PROCESS",
  },
  OFFER_ACCEPTED: {
    key: "OFFER_ACCEPTED",
  },
  IMMIGRATION_PROCESS: {
    key: "IMMIGRATION_PROCESS",
  },
  HIRED: {
    key: "HIRED",
  },
};

export const sources = [
  {
    key: SourceEnum.TalentBranding,
    name: "Talent Branding",
    icon: "/assets/icon_micoach.svg",
  },
  {
    key: SourceEnum.Linkedin,
    name: "Linkedin",
    icon: "/assets/linkedin.svg",
  },
  {
    key: SourceEnum.LinkedinJobPosting,
    name: "Linkedin Job Posting",
    icon: "/assets/linkedin.svg",
  },
  {
    key: SourceEnum.Referral,
    name: "Referral",
    icon: "/assets/icon_referral.svg",
  },
  {
    key: SourceEnum.CareersEmail,
    name: "Careers Email",
    icon: "/assets/website_source.png",
  },
  // TODO: Update to use the commun library
  {
    key: "ITJ_WEBSITE",
    name: "ITJ Website",
    icon: "/assets/itj_website_source.png",
  },
  {
    key: SourceEnum.Indeed,
    name: "Indeed",
    icon: "/assets/website_source.png",
  },
  {
    key: SourceEnum.EmpleoNuevo,
    name: "Empleo Nuevo",
    icon: "/assets/empleonuevo_source.svg",
  },
  {
    key: SourceEnum.EmpleosTi,
    name: "Empleos TI",
    icon: "/assets/website_source.png",
  },
  {
    key: SourceEnum.Glassdoor,
    name: "Glassdoor",
    icon: "/assets/website_source.png",
  },
  {
    key: SourceEnum.Itjuana,
    name: "ITJ",
    icon: "/assets/website_source.png",
  },
  {
    key: SourceEnum.Micoach,
    name: "Mi Coach",
    icon: "/assets/icon_micoach.svg",
  },
  {
    key: SourceEnum.JobFairs,
    name: "Job Fairs",
    icon: "/assets/website_source.png",
  },
  {
    key: SourceEnum.Occ,
    name: "OCC",
    icon: "/assets/website_source.png",
  },
  {
    key: SourceEnum.SocialMedia,
    name: "Social Media",
    icon: "/assets/website_source.png",
  },
  {
    key: SourceEnum.UniversityEvents,
    name: "University Events",
    icon: "/assets/website_source.png",
  },
  {
    key: SourceEnum.GatherHr,
    name: "GatherHR",
    icon: "/assets/website_source.png",
  },
  {
    key: SourceEnum.MicoachDb,
    name: "miCoach DB",
    icon: "/assets/micoachdb_source.svg",
  },
  {
    key: SourceEnum.Other,
    name: "Other",
    icon: "/assets/website_source.png",
  },
];

export const checklistTypes = {
  RESUME: { key: "RESUME", label: "Resume" },
  INTAKE_FORM: { key: "INTAKE_FORM", label: "Pre-screening form" },
  CODE_TEST: { key: "CODE_TEST", label: "Coding Challenge" },
  PAY_STUB: { key: "PAY_STUB", label: "Pay Stub" },
  OFFICIAL_ID: { key: "OFFICIAL_ID", label: "Official ID (INE)" },
  BIRTH_CERTIFICATE: { key: "BIRTH_CERTIFICATE", label: "Birth certificate" },
  CURP: { key: "CURP", label: "CURP" },
  IMSS_NUMBER: {
    key: "IMSS_NUMBER",
    label: "Constancia semanas cotizadas IMSS",
  },
  PROOF_ADDRESS: { key: "PROOF_ADDRESS", label: "Proof of address" },
  PAY_STUB_BANK_ACCOUNT: {
    key: "PAY_STUB_BANK_ACCOUNT",
    label: "Bank account statement",
  },
  PROOF_TAX_STATUS: { key: "PROOF_TAX_STATUS", label: "Proof of tax status" },
  CURRENT_BENEFITS_FORM: {
    key: "CURRENT_BENEFITS_FORM",
    label: "Current benefits form",
  },
  WORK_REFERENCES_FORM: {
    key: "WORK_REFERENCES_FORM",
    label: "Work references form",
  },
  NEW_EMPLOYEE_FORM: {
    key: "NEW_EMPLOYEE_FORM",
    label: "New employee form",
  },
  ID_PHOTO: {
    key: "ID_PHOTO",
    label: "ID photo",
  },
  EDUCATIONAL_CERTIFICATE: {
    key: "EDUCATIONAL_CERTIFICATE",
    label: "Educational certificate",
  },
  DECLARACION_CREDITOS_ACTIVOS: {
    key: "DECLARACION_CREDITOS_ACTIVOS",
    label: "Declaración de créditos activos",
  },
};

export const checklistItems = Object.values(checklistTypes).map(
  ({ key, label }) => ({
    key,
    value: label,
  })
);

export const mimeTypesIcons = [
  {
    key: "application/msword",
    value: faFileWord,
  },
  {
    key: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    value: faFileWord,
  },
  {
    key: "application/vnd.ms-excel",
    value: faFileExcel,
  },
  {
    key: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    value: faFileExcel,
  },
  {
    key: "application/pdf",
    value: faFilePdf,
  },
  {
    key: "image/jpeg",
    value: faFileImage,
  },
  {
    key: "image/png",
    value: faFileImage,
  },
  {
    key: "text/csv",
    value: faFileCsv,
  },
];

export const eventResourceIcons = [
  {
    type: "pdf",
    name: faFilePdf,
    color: "#ff4b4b",
  },
  {
    type: "github",
    name: faGithub,
    color: "#000000",
  },
];

export const positionProfiles = [
  { key: "IOS_DEVELOPER", value: "iOS Developer" },
  { key: "ANDROID_DEVELOPER", value: "Android Developer" },
  { key: "SCRUM_MASTER", value: "SCRUM Master" },
  { key: "PRODUCT OWNER", value: "Product Owner" },
  { key: "UX_DESIGNER", value: "UX Designer" },
  { key: "ENGINEERING_MANAGER", value: "Engineering Manager" },
  { key: "MANUAL_TESTER", value: "Manual Tester" },
  { key: "AUTOMATION_TESTER", value: "Automation Tester" },
  { key: "OTHER", value: "Other" },
];

export const experienceLevels = [
  { key: "TRAINEE", value: "Trainee" },
  { key: "JUNIOR", value: "Junior" },
  { key: "MIDDLE", value: "Middle" },
  { key: "SENIOR", value: "Senior" },
  { key: "STAFF", value: "Staff" },
  { key: "PRINCIPAL", value: "Principal" },
];

export const positionStatus = [
  { value: "ON_HOLD", label: "On hold" },
  { value: "OPEN", label: "Open" },
  { value: "CLOSED", label: "Closed" },
];

export const prospectStatus = [
  { value: "ACTIVE", label: "Active" },
  { value: "CANCELLED", label: "Rejected" },
];

export const visibility = [
  { key: "PUBLIC", value: "Public" },
  { key: "PRIVATE", value: "Private" },
];

export const positionTypes = [
  { key: "FULL-TIME", value: "Full-time" },
  { key: "CONTRACTOR", value: "Contractor" },
];

export const remoteOptions = [
  {
    value: 1,
    label: "Yes",
  },
  {
    value: 0,
    label: "No",
  },
];

export const applicationProcessStatus = {
  active: "ACTIVE",
  cancelled: "CANCELLED",
  completed: "COMPLETED",
};

export const skillCategories = {
  HARD: { key: "HARD", label: "Hard" },
  SOFT: { key: "SOFT", label: "Soft" },
  TECH: { key: "TECH", label: "Technical" },
};

export const prescreeningSections = [
  { value: "SALARY_EXPECTATIONS", label: "Salary expectations" },
  { value: "RELOCATE", label: "Relocate" },
  { value: "TRAVEL_DOCUMENTS", label: "Travel documents" },
  { value: "CURRENT_JOB", label: "Current job" },
  { value: "SOURCE", label: "Source" },
  { value: "QUESTIONNAIRE", label: "Questionnaire" },
  { value: "COMPETENCIES", label: "Competencies" },
  { value: "ENGLISH", label: "English" },
  { value: "CODING_CHALLENGE", label: "Coding challenge" },
  { value: "ADDITIONAL_INFORMATION", label: "Additional information" },
];

export const emailSubjectOptions = [
  {
    value: "FIRST_CONTACT",
    label: "First contact",
    subject: "ITJ Job Opportunity",
  },
];

export const MIN_EVALUATION_REQUESTS = 3;
export const MAX_EVALUATION_REQUESTS = 30;

export const countries = [
  // ARGENTINA
  {
    isoCode: "ARG",
    name: "Argentina",
    callingCode: "+54",
    flag: "https://www.countryflags.io/ar/flat/64.png",
    states: [
      { isoCode: "C", name: "Ciudad Autonoma de Buenos Aires" },
      { isoCode: "B", name: "Buenos Aires" },
      { isoCode: "K", name: "Catamarca" },
      { isoCode: "H", name: "Chaco" },
      { isoCode: "U", name: "Chubut" },
      { isoCode: "X", name: "Cordoba" },
      { isoCode: "W", name: "Corrientes" },
      { isoCode: "E", name: "Entre Rios" },
      { isoCode: "P", name: "Formosa" },
      { isoCode: "Y", name: "Jujuy" },
      { isoCode: "L", name: "La Pampa" },
      { isoCode: "F", name: "La Rioja" },
      { isoCode: "M", name: "Mendoza" },
      { isoCode: "N", name: "Misiones" },
      { isoCode: "Q", name: "Neuquen" },
      { isoCode: "R", name: "Rio Negro" },
      { isoCode: "A", name: "Salta" },
      { isoCode: "J", name: "San Juan" },
      { isoCode: "D", name: "San Luis" },
      { isoCode: "Z", name: "Santa Cruz" },
      { isoCode: "S", name: "Santa Fe" },
      { isoCode: "G", name: "Santiago del Estero" },
      { isoCode: "V", name: "Tierra del Fuego" },
      { isoCode: "T", name: "Tucuman" },
    ],
  },
  {
    isoCode: "BOL",
    name: "Bolivia",
    callingCode: "+591",
    flag: "https://www.countryflags.io/bo/flat/64.png",
    states: [],
  },
  // BRAZIL
  {
    isoCode: "BRA",
    name: "Brazil",
    callingCode: "+55",
    flag: "https://www.countryflags.io/br/flat/64.png",
    states: [
      { isoCode: "AC", name: "Acre" },
      { isoCode: "AL", name: "Alagoas" },
      { isoCode: "AP", name: "Amapa" },
      { isoCode: "AM", name: "Amazonas" },
      { isoCode: "BA", name: "Bahia" },
      { isoCode: "CE", name: "Ceara" },
      { isoCode: "DF", name: "Distrito Federal" },
      { isoCode: "ES", name: "Espirito Santo" },
      { isoCode: "GO", name: "Goias" },
      { isoCode: "MA", name: "Maranhao" },
      { isoCode: "MT", name: "Mato Grosso" },
      { isoCode: "MS", name: "Mato Grosso do Sul" },
      { isoCode: "MG", name: "Minas Gerais" },
      { isoCode: "PA", name: "Para" },
      { isoCode: "PB", name: "Paraiba" },
      { isoCode: "PR", name: "Parana" },
      { isoCode: "PE", name: "Pernambuco" },
      { isoCode: "PI", name: "Piaui" },
      { isoCode: "RJ", name: "Rio de Janeiro" },
      { isoCode: "RN", name: "Rio Grande do Norte" },
      { isoCode: "RS", name: "Rio Grande do Sul" },
      { isoCode: "RO", name: "Rondonia" },
      { isoCode: "RR", name: "Roraima" },
      { isoCode: "SC", name: "Santa Catarina" },
      { isoCode: "SP", name: "Sao Paulo" },
      { isoCode: "SE", name: "Sergipe" },
      { isoCode: "TO", name: "Tocantins" },
    ],
  },
  // CANADA
  {
    isoCode: "CAN",
    name: "Canada",
    callingCode: "+1",
    flag: "https://www.countryflags.io/ca/flat/64.png",
    states: [
      { isoCode: "AB", name: "Alberta" },
      { isoCode: "BC", name: "British Columbia" },
      { isoCode: "MB", name: "Manitoba" },
      { isoCode: "NB", name: "New Brunswick" },
      { isoCode: "NL", name: "Newfoundland and Labrador" },
      { isoCode: "NS", name: "Nova Scotia" },
      { isoCode: "ON", name: "Ontario" },
      { isoCode: "PE", name: "Prince Edward Island" },
      { isoCode: "QC", name: "Quebec" },
      { isoCode: "SK", name: "Saskatchewan" },
      { isoCode: "NT", name: "Northwest Territories" },
      { isoCode: "NU", name: "Nunavut" },
      { isoCode: "YT", name: "Yukon" },
    ],
  },
  {
    isoCode: "CHL",
    name: "Chile",
    callingCode: "+56",
    flag: "https://www.countryflags.io/cl/flat/64.png",
    states: [],
  },
  // COLOMBIA
  {
    isoCode: "COL",
    name: "Colombia",
    callingCode: "+57",
    flag: "https://www.countryflags.io/co/flat/64.png",
    states: [
      { isoCode: "AMA", name: "Amazonas" },
      { isoCode: "DC", name: "Distrito Capital de Bogota" },
      { isoCode: "ANT", name: "Antioquia" },
      { isoCode: "ARA", name: "Arauca" },
      { isoCode: "ATL", name: "Atlantico" },
      { isoCode: "BOL", name: "Bolivar" },
      { isoCode: "BOY", name: "Boyaca" },
      { isoCode: "CAL", name: "Caldas" },
      { isoCode: "CAQ", name: "Caqueta" },
      { isoCode: "CAS", name: "Casanare" },
      { isoCode: "CAU", name: "Cauca" },
      { isoCode: "CES", name: "Cesar" },
      { isoCode: "COR", name: "Cordoba" },
      { isoCode: "CUN", name: "Cundinamarca" },
      { isoCode: "CHO", name: "Choco" },
      { isoCode: "GUA", name: "Guainia" },
      { isoCode: "GUV", name: "Guaviare" },
      { isoCode: "HUI", name: "Huila" },
      { isoCode: "LAG", name: "La Guajira" },
      { isoCode: "MAG", name: "Magdalena" },
      { isoCode: "MET", name: "Meta" },
      { isoCode: "NAR", name: "Nariño" },
      { isoCode: "NSA", name: "Norte de Santander" },
      { isoCode: "PUT", name: "Putumayo" },
      { isoCode: "QUI", name: "Quindio" },
      { isoCode: "RIS", name: "Risaralda" },
      { isoCode: "SAP", name: "San Andres, Providencia y Santa Catalina" },
      { isoCode: "SAN", name: "Santander" },
      { isoCode: "SUC", name: "Sucre" },
      { isoCode: "TOL", name: "Tolima" },
      { isoCode: "VAC", name: "Valle del Cauca Valle" },
      { isoCode: "VAU", name: "Vaupes" },
      { isoCode: "VID", name: "Vichada" },
    ],
  },
  {
    isoCode: "CRI",
    name: "Costa Rica",
    callingCode: "+506",
    flag: "https://www.countryflags.io/cr/flat/64.png",
    states: [],
  },
  {
    isoCode: "DOM",
    name: "Dominican Republic",
    callingCode: "+1",
    flag: "https://www.countryflags.io/do/flat/64.png",
    states: [],
  },
  // ECUADOR
  {
    isoCode: "ECU",
    name: "Ecuador",
    callingCode: "+593",
    flag: "https://www.countryflags.io/ec/flat/64.png",
    states: [
      { isoCode: "A", name: "Azuay " },
      { isoCode: "B", name: "Bolivar" },
      { isoCode: "F", name: "Cañar" },
      { isoCode: "C", name: "Carchi" },
      { isoCode: "H", name: "Chimborazo" },
      { isoCode: "X", name: "Cotopaxi" },
      { isoCode: "O", name: "El Oro" },
      { isoCode: "E", name: "Esmeraldas" },
      { isoCode: "W", name: "Galapagos" },
      { isoCode: "G", name: "Guayas" },
      { isoCode: "I", name: "Imbabura" },
      { isoCode: "L", name: "Loja" },
      { isoCode: "R", name: "Los Rios" },
      { isoCode: "M", name: "Manabi" },
      { isoCode: "S", name: "Morona Santiago" },
      { isoCode: "N", name: "Napo" },
      { isoCode: "D", name: "Orellana" },
      { isoCode: "Y", name: "Pastaza" },
      { isoCode: "P", name: "Pichincha" },
      { isoCode: "SE", name: "Santa Elena" },
      { isoCode: "SD", name: "Santo Domingo de los Tsachilas" },
      { isoCode: "U", name: "Sucumbios" },
      { isoCode: "T", name: "Tungurahua" },
      { isoCode: "Z", name: "Zamora Chinchipe" },
    ],
  },
  {
    isoCode: "SLV",
    name: "El Salvador",
    callingCode: "+503",
    flag: "https://www.countryflags.io/sv/flat/64.png",
    states: [],
  },
  {
    isoCode: "GTM",
    name: "Guatemala",
    callingCode: "+502",
    flag: "https://www.countryflags.io/gt/flat/64.png",
    states: [],
  },
  {
    isoCode: "HTI",
    name: "Haiti",
    callingCode: "+509",
    flag: "https://www.countryflags.io/ht/flat/64.png",
    states: [],
  },
  {
    isoCode: "HND",
    name: "Honduras",
    callingCode: "+504",
    flag: "https://www.countryflags.io/hn/flat/64.png",
    states: [],
  },
  {
    isoCode: "IND",
    name: "India",
    callingCode: "+91",
    flag: "https://www.countryflags.io/in/flat/64.png",
    states: [],
  },
  // MEXICO
  {
    isoCode: "MEX",
    name: "Mexico",
    callingCode: "+52",
    flag: "https://www.countryflags.io/mx/flat/64.png",
    states: [
      { isoCode: "AGU", name: "Aguascalientes" },
      { isoCode: "BCN", name: "Baja California" },
      { isoCode: "BCS", name: "Baja California Sur" },
      { isoCode: "CAM", name: "Campeche" },
      { isoCode: "CHP", name: "Chiapas" },
      { isoCode: "CHH", name: "Chihuahua" },
      { isoCode: "COA", name: "Coahuila" },
      { isoCode: "COL", name: "Colima" },
      { isoCode: "CMX", name: "Mexico City" },
      { isoCode: "DUR", name: "Durango" },
      { isoCode: "GUA", name: "Guanajuato" },
      { isoCode: "GRO", name: "Guerrero" },
      { isoCode: "HID", name: "Hidalgo" },
      { isoCode: "JAL", name: "Jalisco" },
      { isoCode: "MEX", name: "Estado de Mexico" },
      { isoCode: "MIC", name: "Michoacan" },
      { isoCode: "MOR", name: "Morelos" },
      { isoCode: "NAY", name: "Nayarit" },
      { isoCode: "NLE", name: "Nuevo Leon" },
      { isoCode: "OAX", name: "Oaxaca" },
      { isoCode: "PUE", name: "Puebla" },
      { isoCode: "QUE", name: "Queretaro" },
      { isoCode: "ROO", name: "Quintana Roo" },
      { isoCode: "SLP", name: "San Luis Potosi" },
      { isoCode: "SIN", name: "Sinaloa" },
      { isoCode: "SON", name: "Sonora" },
      { isoCode: "TAB", name: "Tabasco" },
      { isoCode: "TAM", name: "Tamaulipas" },
      { isoCode: "TLA", name: "Tlaxcala" },
      { isoCode: "VER", name: "Veracruz" },
      { isoCode: "YUC", name: "Yucatan" },
      { isoCode: "ZAC", name: "Zacatecas" },
    ],
  },
  {
    isoCode: "NIC",
    name: "Nicaragua",
    callingCode: "+505",
    flag: "https://www.countryflags.io/ni/flat/64.png",
    states: [],
  },
  {
    isoCode: "PAN",
    name: "Panama",
    callingCode: "+507",
    flag: "https://www.countryflags.io/pa/flat/64.png",
    states: [],
  },
  {
    isoCode: "PRY",
    name: "Paraguay",
    callingCode: "+595",
    flag: "https://www.countryflags.io/py/flat/64.png",
    states: [],
  },
  {
    isoCode: "PER",
    name: "Peru",
    callingCode: "+51",
    flag: "https://www.countryflags.io/pe/flat/64.png",
    states: [],
  },
  {
    isoCode: "PRI",
    name: "Puerto Rico",
    callingCode: "+1",
    flag: "https://www.countryflags.io/pr/flat/64.png",
    states: [],
  },
  {
    isoCode: "URY",
    name: "Uruguay",
    callingCode: "+598",
    flag: "https://www.countryflags.io/uy/flat/64.png",
    states: [],
  },
  // USA
  {
    isoCode: "USA",
    name: "United States of America",
    callingCode: "+1",
    flag: "https://www.countryflags.io/us/flat/64.png",
    states: [
      { isoCode: "AL", name: "Alabama" },
      { isoCode: "AK", name: "Alaska" },
      { isoCode: "AZ", name: "Arizona" },
      { isoCode: "AR", name: "Arkansas" },
      { isoCode: "CA", name: "California" },
      { isoCode: "CO", name: "Colorado" },
      { isoCode: "CT", name: "Connecticut" },
      { isoCode: "DE", name: "Delaware" },
      { isoCode: "DC", name: "Distric of Columbia" },
      { isoCode: "FL", name: "Florida" },
      { isoCode: "GA", name: "Georgia" },
      { isoCode: "HI", name: "Hawaii" },
      { isoCode: "ID", name: "Idaho" },
      { isoCode: "IL", name: "Illinois" },
      { isoCode: "IN", name: "Indiana" },
      { isoCode: "IA", name: "Iowa" },
      { isoCode: "KS", name: "Kansas" },
      { isoCode: "KY", name: "Kentucky" },
      { isoCode: "LA", name: "Louisiana" },
      { isoCode: "ME", name: "Maine" },
      { isoCode: "MD", name: "Maryland" },
      { isoCode: "MA", name: "Massachusetts" },
      { isoCode: "MI", name: "Michigan" },
      { isoCode: "MN", name: "Minnesota" },
      { isoCode: "MS", name: "Mississippi" },
      { isoCode: "MO", name: "Missouri" },
      { isoCode: "MT", name: "Montana" },
      { isoCode: "NE", name: "Nebraska" },
      { isoCode: "NV", name: "Nevada" },
      { isoCode: "NH", name: "New Hampshire" },
      { isoCode: "NJ", name: "New Jersey" },
      { isoCode: "NM", name: "New Mexico" },
      { isoCode: "NY", name: "New York" },
      { isoCode: "NC", name: "North Carolina" },
      { isoCode: "ND", name: "North Dakota" },
      { isoCode: "OH", name: "Ohio" },
      { isoCode: "OK", name: "Oklahoma" },
      { isoCode: "OR", name: "Oregon" },
      { isoCode: "PA", name: "Pennsylvania" },
      { isoCode: "RI", name: "Rhode Island" },
      { isoCode: "SC", name: "South Carolina" },
      { isoCode: "SD", name: "South Dakota" },
      { isoCode: "TN", name: "Tennessee" },
      { isoCode: "TX", name: "Texas" },
      { isoCode: "UT", name: "Utah" },
      { isoCode: "VT", name: "Vermount" },
      { isoCode: "VA", name: "Virginia" },
      { isoCode: "WA", name: "Washington" },
      { isoCode: "WV", name: "West Virginia" },
      { isoCode: "WI", name: "Wisconsin" },
      { isoCode: "WY", name: "Wyoming" },
    ],
  },
  // VENEZUELA
  {
    isoCode: "VEN",
    name: "Venezuela",
    callingCode: "+58",
    flag: "https://www.countryflags.io/ve/flat/64.png",
    states: [
      { isoCode: "W", name: "Dependencias Federales" },
      { isoCode: "A", name: "Distrito Capital" },
      { isoCode: "Z", name: "Amazonas " },
      { isoCode: "B", name: "Anzoategui	" },
      { isoCode: "C", name: "Apure" },
      { isoCode: "D", name: "Aragua	" },
      { isoCode: "E", name: "Barinas" },
      { isoCode: "F", name: "Bolivar" },
      { isoCode: "G", name: "Carabobo" },
      { isoCode: "H", name: "Cojedes" },
      { isoCode: "Y", name: "Delta Amacuro" },
      { isoCode: "I", name: "Falcon	" },
      { isoCode: "J", name: "Guarico" },
      { isoCode: "K", name: "Lara" },
      { isoCode: "L", name: "Merida" },
      { isoCode: "M", name: "Miranda" },
      { isoCode: "N", name: "Monagas" },
      { isoCode: "O", name: "Nueva Esparta" },
      { isoCode: "P", name: "Portuguesa	" },
      { isoCode: "R", name: "Sucre" },
      { isoCode: "S", name: "Tachira" },
      { isoCode: "T", name: "Trujillo" },
      { isoCode: "X", name: "La Guaira" },
      { isoCode: "U", name: "Yaracuy" },
      { isoCode: "V", name: "Zulia" },
    ],
  },
];

export const trainingTypes = [
  { name: "All Trainings", value: "" },
  { name: "Bootcamps", value: "Bootcamp" },
  { name: "Tech Talks", value: "Tech & !Tech Talk" },
];

export const proficiencyLevels = [
  { level: 1, levelText: "Beginner" },
  { level: 2, levelText: "Practitioner" },
  { level: 3, levelText: "Specialist" },
  { level: 4, levelText: "Expert" },
];

export const taskBarCategoryTypes = {
  MY_TASKS: { name: "My tasks", value: "My tasks" },
  FEEDBACK: { name: "Feedback", value: "Feedback" },
};

export const banners = {
  TRAINING: {
    title: "Training on demand",
    description: (
      <span>
        You missed one of our Tech &amp; !Tech Talk? Now you can access to the
        content and learn to your own pace. Earn points and unlock privileged
        content to empower your career.
      </span>
    ),
    image: assets.TRAINING,
    alt: "Training on demand",
    buttonName: "Go to training on demand",
    path: "trainings",
  },
  HACKATON: {
    image:
      "https://micoach-prod-bucket.s3.amazonaws.com/events/b8918360-b335-4907-b9c0-2fe5ec460ba7",
    alt: "Hackaton",
  },
  AGILE_BOOTCAMP: {
    image:
      "https://micoach-prod-bucket.s3.amazonaws.com/events/e1f92a85-9c20-4755-b04a-01df0113cc0f",
    alt: "Agile Bootcamp",
  },
  TECH_AND_NONTECH_TALK: {
    image:
      "https://micoach-prod-bucket.s3.amazonaws.com/events/d7474c86-5794-4c06-b39e-8ffa248cd49a",
    alt: "Tech & !Tech Talk",
  },
  DASHBOARD_BANNER: {
    image:
      "https://micoach-prod-bucket.s3.amazonaws.com/events/dashboard-banner",
    alt: "Dashboard Banner",
  },
  CAREER_PATH: {
    alt: "Career Path Banner",
    mobile: `${ASSETS_BUCKET}/career-path/banner/carousel-mobile.png`,
    tablet: `${ASSETS_BUCKET}/career-path/banner/carousel-tablet.png`,
    desktop: `${ASSETS_BUCKET}/career-path/banner/carousel-desktop.png`,
  },
  PERFORMANCE_EVALUATION: {
    alt: "Performance Evaluation",
    mobile: `${ASSETS_BUCKET}/performance-mobile.jpg`,
    tablet: `${ASSETS_BUCKET}/performance-tablet.jpg`,
    desktop: `${ASSETS_BUCKET}/performance-desktop.jpg`,
  },
};

export const companiesDomains = [
  "itj.com",
  "itijuana.com",
  "itjuana.com",
  "insulet.com",
  "dexcom.com",
  "teradata.com",
  "housecallpro.com",
  "xiltrix.com",
  "xiltrixusa.com",
  "lunadna.com",
  "carlsmed.com",
  "curemetrix.com",
  "gotuwired.com",
  "canto.com",
  "outsetmedical.com",
  "platformscience.com",
  "biosero.com",
  "hub.inc",
  "commsafe.ai",
  "americannational.com",
];

export const roleQuiz = {
  QUESTION_ONE: {
    question: "What are you good at?",
    stepNumber: 0,
    minSelection: 1,
    maxSelection: 3,
    exitQuiz: true,
  },
  QUESTION_TWO: {
    question: "Which of these functions do you find interesting?",
    stepNumber: 1,
    minSelection: 1,
    maxSelection: 3,
  },
  QUESTION_THREE: {
    question: "Do you use or know any of these technologies/frameworks?",
    stepNumber: 2,
    minSelection: 1,
    maxSelection: 10,
  },
  ANSWER: {
    title: "Congrats! Choose your role!",
    subtitle:
      "According to your answers, we show you the three better options for you.",
    stepNumber: 3,
    minSelection: 1,
    maxSelection: 1,
    exitQuiz: true,
    icon: faCheckCircle,
    iconPosition: "left",
  },
};

export const menuHiringEventsTable = {
  VIEW_INTERVIEWS: {
    name: "Interviews",
    eventKey: "view-interviews",
    icon: faCalendar,
  },
  EDIT_HIRING_EVENT: {
    name: "Edit",
    eventKey: "edit-hiring-event",
    icon: faPen,
  },
};

export const menuInterviewsCalendar = {
  EDIT_INTERVIEW: {
    name: "Edit",
    eventKey: "edit-interview",
    icon: faPen,
  },
  EDIT_PANELISTS: {
    name: "Edit panelists",
    eventKey: "edit-panelists",
    icon: faUsers,
  },
};

export const API = {
  ACHIEVEMENT: "/achievement",
  APPLICATION_PROCESS: "/application-process",
  CANDIDATE: "/candidate",
  CATALOG: "/catalog",
  COMPANIES: "/company",
  FILE: "/file",
  FILE_DOWNLOAD_URL: "/file/downloadUrl",
  JOBS: "/job",
  PROSPECT_PROCESS: "/prospect-process",
  REFERRAL: "/referral",
  PUBLIC_CANDIDATE: "/public/candidate",
  PUBLIC_CATALOG: "/public/catalog",
};

export const MICOACH_API_URL = process.env.REACT_APP_BACKEND_URL;

export const PROCESSES = {
  APPLICATION: "application-process",
  PROSPECT: "prospect-process",
};

export const CATALOG_TYPES = {
  APPLICATION_PROCESS_REJECTION_REASON: "APPLICATION_PROCESS_REJECTION_REASON",
  PROSPECT_PROCESS_REJECTION_REASON: "PROSPECT_PROCESS_REJECTION_REASON",
  TAG: "TAG",
};

export const EMAIL_TEMPLATES_TYPES = {
  DISCARDED: "DISCARDED",
  PROSPECT_PROCESS_DISCARDED_APPLICATION:
    "PROSPECT_PROCESS_DISCARDED_APPLICATION",
  PROSPECT_PROCESS_DISCARDED_REFERRAL: "PROSPECT_PROCESS_DISCARDED_REFERRAL",
};

export const MENU_PROSPECT_PROCESS = {
  MOVE_TO_KANBAN: {
    name: "Move to Kanban",
    eventKey: "move-to-kanban",
    icon: faArrowRight,
  },
  TAGS: {
    name: "Tags",
    eventKey: "tags",
    icon: faTag,
  },
  REJECT: {
    name: "Reject",
    eventKey: "reject-process",
    icon: faUserXmark,
  },
};

export const CANDIDATE_EMPLOYMENT = {
  TRAINEE: { label: "Trainee", value: "TRAINEE" },
  FTE: { label: "FTE", value: "FTE" },
};

export const EVENT_TYPES = {
  BOOTCAMP: { key: "Bootcamp", label: "Bootcamp" },
  TECH_TALK: { key: "Tech & !Tech Talk", label: "Tech Talk" },
};

export const PROSPECT_PROCESS_SOURCE_FLOWS = [
  { value: "APPLICATION", label: "Application" },
  { value: "REFERRAL", label: "Referral" },
];

export const DOCUMENT_REQUEST_ORIGINS = {
  OFFER_PROCESS: "OFFER_PROCESS",
  OFFER_ACCEPTED_PROCESS: "OFFER_ACCEPTED_PROCESS",
  MANUAL_PROCESS: "MANUAL_PROCESS",
};

export const ACHIEVEMENT_STATUS = {
  DRAFT: "Draft",
  SENT: "Sent",
};

export const FILE_ENTITIES = { ACHIEVEMENT: "ACHIEVEMENT" };

export const ACHIEVEMENT_FILE_CATEGORIES = {
  ATTENDEES: "ATTENDEES_LIST",
  CERTIFICATE: "CERTIFICATE_BACKGROUND",
  BADGE: "BADGE_IMAGE",
};

export const SUBMIT_TYPES = {
  SAVE: 1,
  PREVIEW: 2,
};

export const ACHIEVEMENT_CERTIFICATE_DUMMY_DATA = {
  name: "Luis Miguel Flores Díaz",
  points: 12,
};

export const ACHIEVEMENT_TABLE_MENU = {
  EDIT: {
    name: "Edit",
    icon: faPen,
    eventKey: "Edit",
  },
  DELETE: {
    name: "Delete",
    icon: faTrashCan,
    eventKey: "Delete",
    iconClassName: "WarningEllipsisOption",
  },
};
