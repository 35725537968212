import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useParams } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import { UserRoleEnum } from "@micoach/js-sdk";
import dayjs from "dayjs";

import GoBackButton from "Components/GoBackButton";
import InterviewAddEditModal from "Components/Interview/InterviewAddEditModal";
import InterviewPanelistsModal from "Components/Interview/InterviewPanelistsModal";
import InterviewsCalendar from "Components/Interview/InterviewsCalendar";
import Loading from "Components/Loading";
import { useHiringEventInterviews } from "Hooks/useHiringEventInterviews";
import { useHiringEvent } from "Hooks/useHiringEvents";
import { useUsersByRole } from "Hooks/useUsers";
import { menuInterviewsCalendar } from "constants.js";
import {
  addOrReplaceElementInArray,
  getFormattedTimeZoneDate,
  getRoles,
} from "utils.js";

const ListHiringEventInterviews = () => {
  const [selectedInterview, setSelectedInterview] = useState();
  const [showInterviewAddEditModal, setShowInterviewAddEditModal] =
    useState(false);
  const [showInterviewPanelistModal, setShowInterviewPanelistModal] =
    useState(false);
  const [activeApplicationProcesses, setActiveApplicationProcesses] = useState(
    []
  );

  const { users: recruiters } = useUsersByRole({
    role: UserRoleEnum.Recruiter,
    enabled: true,
  });
  const { id } = useParams();
  const { hiringEvent, loadHiringEvent, loadingHiringEvent, hiringEventError } =
    useHiringEvent(id);
  const {
    hiringEventInterviews,
    loadHiringEventInterviews,
    setHiringEventInterviews,
    loadingHiringEventInterviews,
    hiringEventInterviewsError,
  } = useHiringEventInterviews(id);
  const { keycloak } = useKeycloak();

  const isRecruiter = getRoles(keycloak).some(
    (userRole) => UserRoleEnum.Recruiter === userRole
  );

  const handleSaved = (hiringEventInterview) => {
    setHiringEventInterviews(
      addOrReplaceElementInArray(hiringEventInterviews, hiringEventInterview)
    );
  };

  const handleCloseInterviewAddEditModal = () => {
    setSelectedInterview(null);
    setShowInterviewAddEditModal(false);
  };

  const handleCloseInterviewPanelistsModal = () => {
    setSelectedInterview(null);
    setShowInterviewPanelistModal(false);
  };

  const handleSelectMenuOption = (option, interview) => {
    switch (option) {
      case menuInterviewsCalendar.EDIT_INTERVIEW.eventKey:
        setSelectedInterview(interview);
        setShowInterviewAddEditModal(true);
        break;

      case menuInterviewsCalendar.EDIT_PANELISTS.eventKey:
        setSelectedInterview(interview);
        setShowInterviewPanelistModal(true);
        break;
      default:
        break;
    }
  };

  const handleUpdate = (hiringEventInterview) => {
    setHiringEventInterviews((prevHiringEventInterviews) =>
      addOrReplaceElementInArray(
        prevHiringEventInterviews,
        hiringEventInterview
      )
    );
    setShowInterviewPanelistModal(false);
  };

  useEffect(() => {
    loadHiringEvent(id);
    loadHiringEventInterviews(id);
  }, [id, loadHiringEvent, loadHiringEventInterviews]);

  useEffect(() => {
    if (hiringEventInterviews) {
      setActiveApplicationProcesses(
        hiringEventInterviews.map(
          (hiringEventInterview) => hiringEventInterview.applicationProcess?._id
        )
      );
    }
  }, [hiringEventInterviews]);

  return (
    <>
      {isRecruiter && (
        <GoBackButton path="/hiring-events" label="Hiring Events" />
      )}

      <InterviewAddEditModal
        show={showInterviewAddEditModal}
        recruiters={recruiters}
        hiringEvent={hiringEvent}
        interview={selectedInterview}
        activeApplicationProcesses={activeApplicationProcesses}
        onClose={handleCloseInterviewAddEditModal}
        onSaved={handleSaved}
      />

      <InterviewPanelistsModal
        show={showInterviewPanelistModal}
        hiringEvent={hiringEvent}
        interview={selectedInterview}
        onUpdate={handleUpdate}
        onClose={handleCloseInterviewPanelistsModal}
      />

      {loadingHiringEvent || loadingHiringEventInterviews ? (
        <Loading />
      ) : hiringEventError?.status ? (
        <>
          <h1 className="text-center">{hiringEventError?.message}</h1>
        </>
      ) : hiringEventInterviewsError?.status ? (
        <>
          <h1 className="text-center">{hiringEventInterviewsError?.message}</h1>
        </>
      ) : (
        <>
          <div className="ScreenTitle d-flex align-items-center justify-content-between">
            <h1>
              {`${hiringEvent?.company?.name} - ${getFormattedTimeZoneDate(
                hiringEvent?.date
              )}`}
            </h1>
            {dayjs(hiringEvent?.date).isAfter(dayjs()) && (
              <Button
                variant="secondary"
                className="AddButton"
                onClick={() => setShowInterviewAddEditModal(true)}
              >
                Create Interview
              </Button>
            )}
          </div>

          <InterviewsCalendar
            interviews={hiringEventInterviews}
            date={hiringEvent?.date}
            onSelectMenuOption={handleSelectMenuOption}
          />
        </>
      )}
    </>
  );
};

export default ListHiringEventInterviews;
