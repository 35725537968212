import { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AchievementForm from "Components/Admin/Achievements/AchievementForm";
import { useAchievements } from "Hooks/useAchievements";
import { useFiles, useUploadFiles } from "Hooks/useFiles";
import {
  ACHIEVEMENT_FILE_CATEGORIES,
  FILE_ENTITIES,
  SUBMIT_TYPES,
} from "constants.js";

const DEFAULT_FORM = {
  name: "",
  message: "",
  earnedDate: null,
  audience: "",
  [ACHIEVEMENT_FILE_CATEGORIES.ATTENDEES]: "",
  [ACHIEVEMENT_FILE_CATEGORIES.CERTIFICATE]: "",
  [ACHIEVEMENT_FILE_CATEGORIES.BADGE]: "",
};
const CERTIFICATE_BADGE_CATEGORIES = [
  ACHIEVEMENT_FILE_CATEGORIES.CERTIFICATE,
  ACHIEVEMENT_FILE_CATEGORIES.BADGE,
];
const NEW_FILE_ID = "NEW";

const AchievementAddEditModal = (props) => {
  const { show = false, achievement = {}, onSaved, onClose } = props;

  const { createAchievement, updateAchievement } = useAchievements();
  const { createFile, updateFile } = useFiles();
  const { uploadFile, files, setFiles, dropFile, deleteFile } = useUploadFiles(
    createFile,
    updateFile
  );

  const [submitType, setSubmitType] = useState(SUBMIT_TYPES.SAVE);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    control,
    clearErrors,
    setError,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const isEditing = !!achievement?._id;

  const mapFileToCategory = (file, category) =>
    file?._id ? { [category]: file._id } : {};
  const mapFileDetails = (file) =>
    file?.name
      ? {
          name: file.name,
          type: file.contentType,
          size: file.size,
          uploaded: true,
        }
      : null;

  const achievementFormValues = useMemo(() => {
    if (!achievement?._id) {
      return DEFAULT_FORM;
    }

    const earnedDate = {
      ...(achievement.earnedDate && {
        earnedDate: new Date(achievement.earnedDate),
      }),
    };
    const fileValues = {
      ...mapFileToCategory(
        achievement.attendeesFile,
        ACHIEVEMENT_FILE_CATEGORIES.ATTENDEES
      ),
      ...mapFileToCategory(
        achievement.badgeImageFile,
        ACHIEVEMENT_FILE_CATEGORIES.BADGE
      ),
      ...mapFileToCategory(
        achievement.certificateBackgroundFile,
        ACHIEVEMENT_FILE_CATEGORIES.CERTIFICATE
      ),
    };

    return {
      ...DEFAULT_FORM,
      ...achievement,
      ...earnedDate,
      ...fileValues,
    };
  }, [achievement]);

  const setAchievementFiles = useCallback(() => {
    if (!achievement?._id) {
      setFiles({});
    } else {
      setFiles(
        [
          {
            file: achievement.attendeesFile,
            category: ACHIEVEMENT_FILE_CATEGORIES.ATTENDEES,
          },
          {
            file: achievement.certificateBackgroundFile,
            category: ACHIEVEMENT_FILE_CATEGORIES.CERTIFICATE,
          },
          {
            file: achievement.badgeImageFile,
            category: ACHIEVEMENT_FILE_CATEGORIES.BADGE,
          },
        ].reduce((acc, { file, category }) => {
          const details = mapFileDetails(file);
          if (details) {
            acc[category] = details;
          }
          return acc;
        }, {})
      );
    }
  }, [achievement, setFiles]);

  const uploadAchievementFilesAndGetFileIds = async () => {
    const fileIdPropertyMap = {
      [ACHIEVEMENT_FILE_CATEGORIES.ATTENDEES]: "attendeesFileId",
      [ACHIEVEMENT_FILE_CATEGORIES.CERTIFICATE]: "certificateBackgroundFileId",
      [ACHIEVEMENT_FILE_CATEGORIES.BADGE]: "badgeImageFileId",
    };

    const fileIds = await Promise.all(
      Object.entries(fileIdPropertyMap).map(async ([category, property]) => {
        const file = files[category];
        let fileId = getValues(category);
        fileId = fileId !== NEW_FILE_ID ? fileId : "";

        if (file) {
          fileId = file.uploaded
            ? fileId
            : await uploadFile({
                file,
                fileId,
                entity: FILE_ENTITIES.ACHIEVEMENT,
                category,
                isPublic: category !== ACHIEVEMENT_FILE_CATEGORIES.ATTENDEES,
              });
        } else {
          fileId = null;
        }

        return [property, fileId];
      })
    );

    return Object.fromEntries(fileIds);
  };

  const handleDropFile = (acceptedFiles, category) => {
    dropFile(acceptedFiles, category);
    if (!getValues(category)) {
      setValue(category, NEW_FILE_ID);
    }

    if (CERTIFICATE_BADGE_CATEGORIES.includes(category)) {
      clearErrors(CERTIFICATE_BADGE_CATEGORIES);
    } else {
      clearErrors(category);
    }
  };

  const handleDeleteFile = (category, isRequired = true) => {
    deleteFile(category);

    if (!isRequired) {
      return;
    }

    if (CERTIFICATE_BADGE_CATEGORIES.includes(category)) {
      setError(ACHIEVEMENT_FILE_CATEGORIES.CERTIFICATE, { type: "required" });
      setError(ACHIEVEMENT_FILE_CATEGORIES.BADGE, { type: "required" });
    } else {
      setError(category, { type: "required" });
    }
  };

  const handleCustomSubmit = (type) => {
    setSubmitType(type);
    handleSubmit((formValues) => onSubmit(formValues, type))();
  };

  const onSubmit = async (formValues, submitType) => {
    try {
      setIsFormDisabled(true);
      const achievementFileIds = await uploadAchievementFilesAndGetFileIds();

      const { name, message, earnedDate, audience } = formValues;
      const body = {
        name,
        message,
        audience,
        ...(earnedDate && { earnedDate: earnedDate.toISOString() }),
        ...achievementFileIds,
      };

      let savedAchievement;

      if (isEditing) {
        savedAchievement = await updateAchievement({
          achievementId: achievement._id,
          body,
        });
      } else {
        savedAchievement = await createAchievement({ body });
      }

      onSaved?.(savedAchievement._id, submitType);
      onClose?.();
      toast.success(
        `Success! The achievement draft has been ${isEditing ? "updated" : "created"}.`
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsFormDisabled(false);
    }
  };

  useEffect(() => {
    if (!show) {
      return;
    }

    reset(achievementFormValues);
    setSubmitType(SUBMIT_TYPES.SAVE);
    setAchievementFiles();

    // TODO: Remove this if it is possible, it is necessary for unit tests
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    show,
    reset,
    achievementFormValues,
    setSubmitType,
    // setAchievementFiles,
  ]);

  return (
    <Modal show={show} onHide={onClose}>
      <Form noValidate>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? "Edit" : "Create"} Achievement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 fs-14">
            To create and send and achievement, upload the required assets
            below. Remember to double check the format and properties of each
            one.
          </div>

          <AchievementForm
            submitType={submitType}
            files={files}
            formProps={{ register, control, errors }}
            disabled={isFormDisabled}
            onDropFile={handleDropFile}
            onDeleteFile={handleDeleteFile}
          />

          <Alert variant="light" className="fs-13">
            <FontAwesomeIcon icon={faInfoCircle} className="BlueTextMedium" />
            <span>At least, one certificate or badge asset is required</span>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="CancelButton"
            disabled={isFormDisabled}
            onClick={() => handleCustomSubmit(SUBMIT_TYPES.SAVE)}
          >
            Save
          </Button>
          <Button
            className="SaveButton"
            disabled={isFormDisabled}
            onClick={() => handleCustomSubmit(SUBMIT_TYPES.PREVIEW)}
          >
            Preview
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

AchievementAddEditModal.propTypes = {
  show: PropTypes.bool,
  achievement: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    message: PropTypes.string,
    earnedDate: PropTypes.string,
    audience: PropTypes.string,
    attendeesFile: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      size: PropTypes.number,
      contentType: PropTypes.string,
    }),
    badgeImageFile: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      size: PropTypes.number,
      contentType: PropTypes.string,
    }),
    certificateBackgroundFile: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      size: PropTypes.number,
      contentType: PropTypes.string,
    }),
  }),
  onSaved: PropTypes.func,
  onClose: PropTypes.func,
};

export default AchievementAddEditModal;
