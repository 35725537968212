import { useState } from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { faDownload, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ShareModal } from "@itj-micoach/micoach-common";
import { saveAs } from "file-saver";

import "Components/Candidate/styles/Achievement.css";
import actionButtonStyles from "Components/Common/styles/ActionButton.module.css";

const Achievement = (props) => {
  const {
    achievement,
    getCertificate,
    isPublic = false,
    isShareable = false,
    isDownloadingCertificate = false,
  } = props;

  const [showShareModal, setShowShareModal] = useState(false);

  const handleClickDownloadPdf = async () => {
    try {
      const blob = await getCertificate({
        achievementId: achievement.achievementId,
      });

      saveAs(
        new Blob([blob]),
        `Certificate ${achievement.name} - ${achievement.candidateName}.pdf`
      );
    } catch (error) {
      toast.error(
        "Error! An error occurred while generating the certificate, please try again."
      );
    }
  };

  const path = `/candidate/${achievement?.candidateId}/achievements/${achievement?.achievementId}`;
  const url = `${process.env.REACT_APP_HOST}${path}`;

  if (!achievement) {
    return <h1>Achievement not found!</h1>;
  }

  return (
    <>
      <ShareModal
        show={showShareModal}
        title="Share Achievement"
        description={achievement.name}
        url={url}
        centered
        onClose={() => setShowShareModal(false)}
        onUrlCopied={() => toast.info("Link copied to clipboard")}
      />
      <div className="Achievement">
        {achievement.coverImageUrl && (
          <div className="CoverImage">
            <img
              src={achievement.coverImageUrl}
              alt={`${achievement.name} cover`}
            />
          </div>
        )}

        <div className="Content">
          {achievement.companyLogoUrl && (
            <div className="CompanyLogo">
              <img src={achievement.companyLogoUrl} alt="Company" />
            </div>
          )}
          <div>Present this achievement to</div>
          <div className="UserName">{achievement.candidateName}</div>
          <div>{achievement.message}</div>
          <div className="EventName">{achievement.name}</div>
        </div>

        <div className="Footer">
          {!isPublic && (
            <>
              <div className="Points">
                <div className="Circle">{achievement.points}</div>
                <span>Points earned</span>
              </div>

              {achievement.hasCertificate && (
                <div className="Download">
                  <div className="Circle">
                    <Button
                      variant="link"
                      onClick={handleClickDownloadPdf}
                      disabled={isDownloadingCertificate}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                  </div>
                  <span>PDF file</span>
                </div>
              )}
            </>
          )}

          {achievement?.badgeImageUrl && (
            <div className="Badge">
              <img
                src={achievement?.badgeImageUrl}
                alt={`${achievement?.name} badge`}
              />
              <span>Badge earned</span>
            </div>
          )}
          {isShareable && (
            <div className="Share">
              <div className="Circle">
                <Button variant="link" onClick={() => setShowShareModal(true)}>
                  <div className={actionButtonStyles.Container}>
                    <FontAwesomeIcon
                      icon={faShare}
                      className={actionButtonStyles.Icon}
                    />
                  </div>
                </Button>
              </div>
              <span>Share achievement</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

Achievement.propTypes = {
  achievement: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    candidateName: PropTypes.string.isRequired,
    achievementId: PropTypes.string.isRequired,
    earnedDate: PropTypes.string.isRequired,
    candidateId: PropTypes.string,
    message: PropTypes.string,
    points: PropTypes.number,
    coverImageUrl: PropTypes.string,
    companyLogoUrl: PropTypes.string,
    badgeImageUrl: PropTypes.string,
    hasCertificate: PropTypes.bool,
  }),
  getCertificate: PropTypes.func.isRequired,
  isDownloadingCertificate: PropTypes.bool,
  isPublic: PropTypes.bool,
  isShareable: PropTypes.bool,
};

export default Achievement;
