import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import { Loading } from "@itj-micoach/micoach-common";

import EllipsisMenu from "Components/Common/EllipsisMenu";
import { ACHIEVEMENT_STATUS, ACHIEVEMENT_TABLE_MENU } from "constants.js";
import { getFormattedLocalDate } from "utils.js";

const MENU_OPTIONS = Object.values(ACHIEVEMENT_TABLE_MENU);

const AchievementTable = (props) => {
  const {
    achievements = [],
    loading = false,
    onClickSelectedMenuOption,
  } = props;

  const columns = [
    {
      name: <strong aria-label="Achieved date">Achieved date</strong>,
      selector: (achievement) => achievement.earnedDate,
      cell: (achievement) => getFormattedLocalDate(achievement.earnedDate),
      minWidth: "130px",
      wrap: true,
      sortable: true,
    },
    {
      name: <strong aria-label="Achievement title">Achievement title</strong>,
      selector: (achievement) => achievement.name,
      minWidth: "210px",
      wrap: true,
      sortable: true,
    },
    {
      name: (
        <strong aria-label="Achievement message">Achievement message</strong>
      ),
      selector: (achievement) => achievement.message,
      minWidth: "300px",
      wrap: true,
      sortable: true,
    },
    {
      name: <strong aria-label="Audience">Audience</strong>,
      selector: (achievement) => achievement.audience,
      minWidth: "120px",
      wrap: true,
      sortable: true,
    },
    {
      name: <strong aria-label="Status">Status</strong>,
      selector: (achievement) => achievement.status,
      minWidth: "120px",
      wrap: true,
      sortable: true,
    },
    {
      name: <strong aria-label="Sent date">Sent date</strong>,
      selector: (achievement) => achievement.sentDate,
      cell: (achievement) => getFormattedLocalDate(achievement.sentDate),
      minWidth: "120px",
      wrap: true,
      sortable: true,
    },
    {
      name: <strong>Actions</strong>,
      cell: (achievement) => (
        <EllipsisMenu
          options={MENU_OPTIONS}
          disabledOptions={
            achievement.status !== ACHIEVEMENT_STATUS.DRAFT
              ? ["Delete", "Edit"]
              : []
          }
          onSelectedOption={(option) =>
            onClickSelectedMenuOption?.(option, achievement)
          }
        />
      ),
      width: "80px",
      wrap: true,
      center: true,
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={achievements}
      progressPending={loading}
      progressComponent={<Loading />}
      persistTableHead
      highlightOnHover
      pagination
      defaultSortFieldId={1}
      defaultSortAsc={false}
    />
  );
};

AchievementTable.propTypes = {
  achievements: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.oneOf(Object.values(ACHIEVEMENT_STATUS)).isRequired,
      earnedDate: PropTypes.string.isRequired,
      message: PropTypes.string,
      audience: PropTypes.string,
      sentDate: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
  onClickSelectedMenuOption: PropTypes.func,
};

export default AchievementTable;
