import { useKeycloak } from "@react-keycloak/web";
import useSWRMutation from "swr/mutation";

import { ACHIEVEMENT_CERTIFICATE_DUMMY_DATA, API } from "constants.js";
import { sendRequest } from "utils.js";

export const useAchievements = () => {
  const { keycloak } = useKeycloak();

  const {
    trigger: getAchievement,
    isMutating: isLoadingAchievement,
    data: achievement = { _id: "", name: "", candidateId: "", earnedDate: "" },
  } = useSWRMutation(API.ACHIEVEMENT, (path, { arg }) =>
    sendRequest(`${path}/${arg.achievementId}`, {
      ...arg,
      accessToken: keycloak.token,
    })
  );

  const {
    trigger: getAchivements,
    isMutating: isLoadingAchivements,
    data: achievements = [],
  } = useSWRMutation(API.ACHIEVEMENT, (path, { arg }) =>
    sendRequest(path, {
      ...arg,
      accessToken: keycloak.token,
    })
  );

  const { trigger: createAchievement, isMutating: isSaving } = useSWRMutation(
    API.ACHIEVEMENT,
    (path, { arg }) =>
      sendRequest(path, {
        ...arg,
        method: "POST",
        accessToken: keycloak.token,
      })
  );

  const { trigger: updateAchievement, isMutating: isUpdating } = useSWRMutation(
    API.ACHIEVEMENT,
    (path, { arg }) =>
      sendRequest(`${path}/${arg.achievementId}`, {
        ...arg,
        method: "PUT",
        accessToken: keycloak.token,
      })
  );

  const { trigger: sendAchievement, isMutating: isSendingAchievement } =
    useSWRMutation(API.ACHIEVEMENT, (path, { arg }) =>
      sendRequest(`${path}/${arg.achievementId}/send`, {
        ...arg,
        method: "PUT",
        accessToken: keycloak.token,
      })
    );

  const {
    trigger: getDummyCertificatePdf,
    isMutating: isLoadingDummyCertificatePdf,
  } = useSWRMutation(API.ACHIEVEMENT, (path, { arg }) =>
    sendRequest(`${path}/${arg.achievementId}/dummy-certificate`, {
      ...arg,
      queryParams: { name: ACHIEVEMENT_CERTIFICATE_DUMMY_DATA.name },
      responseType: "arraybuffer",
      accessToken: keycloak.token,
    })
  );

  const { trigger: deleteAchievement, isMutating: isDeleting } = useSWRMutation(
    API.ACHIEVEMENT,
    (path, { arg }) =>
      sendRequest(`${path}/${arg.achievementId}`, {
        ...arg,
        method: "DELETE",
        accessToken: keycloak.token,
      })
  );

  return {
    achievement,
    achievements,
    getAchivements,
    getAchievement,
    sendAchievement,
    createAchievement,
    updateAchievement,
    getDummyCertificatePdf,
    deleteAchievement,
    isLoadingAchivements,
    isLoadingAchievement,
    isSendingAchievement,
    isLoadingDummyCertificatePdf,
    isSaving,
    isUpdating,
    isDeleting,
  };
};
