import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useHistory } from "react-router-dom";

import AchievementCard from "Components/Candidate/AchievementCard";
import SkeletonCard from "Components/Common/SkeletonCard";
import Loading from "Components/Loading";
import { useWindowSize } from "Hooks/useWindowSize";

const PrincipalAchievementsList = (props) => {
  const { achievements = [], loading = false } = props;

  const history = useHistory();
  const windowSize = useWindowSize();

  if (loading) return <Loading />;

  const achievement1 = achievements.length > 0 ? achievements[0] : null;
  const achievement2 = achievements.length > 1 ? achievements[1] : null;

  const handleClickAchievementCard = (achievement) => {
    windowSize.isMobile
      ? history.push(`/achievements/${achievement.achievementId}`)
      : history.push("achievements", {
          selectedAchievement: achievement,
        });
  };

  return (
    <Row>
      <Col xs={12} md={6}>
        {achievement1 ? (
          <AchievementCard
            achievement={achievement1}
            seeCertificate
            onClick={handleClickAchievementCard}
          />
        ) : (
          <SkeletonCard />
        )}
      </Col>
      <Col xs={12} md={6}>
        {achievement2 ? (
          <AchievementCard
            achievement={achievement2}
            seeCertificate
            onClick={handleClickAchievementCard}
          />
        ) : (
          <SkeletonCard />
        )}
      </Col>
    </Row>
  );
};

PrincipalAchievementsList.propTypes = {
  achievements: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
};

export default PrincipalAchievementsList;
