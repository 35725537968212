import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useHistory } from "react-router-dom";
import { UserRoleEnum } from "@micoach/js-sdk";
import dayjs from "dayjs";
import { addOrReplaceElementInArray } from "utils";

import Restricted from "Components/Common/Restricted";
import GoBackButton from "Components/GoBackButton";
import HiringEventAddEditModal from "Components/HiringEvent/HiringEventAddEditModal";
import HiringEventsTable from "Components/HiringEvent/HiringEventsTable";
import Loading from "Components/Loading";
import { useCompanies } from "Hooks/useCompanies";
import { useHiringEvents } from "Hooks/useHiringEvents";
import { useUsersByRole } from "Hooks/useUsers";
import { menuHiringEventsTable } from "constants.js";

const ListHiringEvents = () => {
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [activeApplicationProcesses, setActiveApplicationProcesses] = useState(
    []
  );
  const [selectedHiringEvent, setSelectedHiringEvent] = useState();

  const history = useHistory();
  const {
    hiringEvents,
    loadingHiringEvents,
    loadHiringEvents,
    setHiringEvents,
  } = useHiringEvents();
  const [companies] = useCompanies();
  const { users: recruiters } = useUsersByRole({
    role: UserRoleEnum.Recruiter,
    enabled: true,
  });

  const handleHiringEventSaved = (hiringEvent) => {
    setHiringEvents(addOrReplaceElementInArray(hiringEvents, hiringEvent));
  };

  const getActiveApplicationProcesses = (hiringEvents) => {
    const applicationProcesses = [];
    hiringEvents.forEach((hiringEvent) => {
      if (dayjs(hiringEvent.date).utc().isAfter(dayjs().utc())) {
        hiringEvent.applicationProcesses.forEach((applicationProcess) => {
          applicationProcesses.push(applicationProcess._id);
        });
      }
    });

    setActiveApplicationProcesses(applicationProcesses);
  };

  const handleClickCreate = () => {
    setSelectedHiringEvent(null);
    setShowAddEditModal(true);
  };

  const handleSelectMenuOption = (option, hiringEvent) => {
    switch (option) {
      case menuHiringEventsTable.VIEW_INTERVIEWS.eventKey:
        history.push(`/hiring-events/${hiringEvent?._id}/interviews`);
        break;
      case menuHiringEventsTable.EDIT_HIRING_EVENT.eventKey:
        setSelectedHiringEvent(hiringEvent);
        setShowAddEditModal(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    loadHiringEvents();
  }, [loadHiringEvents]);

  useEffect(() => {
    if (hiringEvents) {
      getActiveApplicationProcesses(hiringEvents);
    }
  }, [hiringEvents]);

  if (loadingHiringEvents) {
    return <Loading />;
  }

  return (
    <>
      <GoBackButton path="/kanban" label="Kanban" />

      <HiringEventAddEditModal
        show={showAddEditModal}
        companies={companies}
        recruiters={recruiters}
        activeApplicationProcesses={activeApplicationProcesses}
        hiringEvent={selectedHiringEvent}
        onClose={() => setShowAddEditModal(false)}
        onSaved={handleHiringEventSaved}
      />

      <div className="ScreenTitle d-flex align-items-center justify-content-between">
        <h1>Hiring Events</h1>
        {/* TODO: Add Filter */}

        <Restricted roles={[UserRoleEnum.AdminRecruiter]}>
          <Button
            variant="secondary"
            className="AddButton"
            onClick={handleClickCreate}
          >
            Create Event
          </Button>
        </Restricted>
      </div>

      <Card className="Card">
        <Card.Body>
          <HiringEventsTable
            hiringEvents={hiringEvents}
            onSelectMenuOption={handleSelectMenuOption}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default ListHiringEvents;
