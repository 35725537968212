import { useState } from "react";
import Card from "react-bootstrap/Card";
import { toast } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ConfirmModal, FullPageOverlay } from "@itj-micoach/micoach-common";
import { ApplicationProcessStatusEnum } from "@micoach/js-sdk";
import { saveAs } from "file-saver";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

import SelectedFiltersV2 from "Components/Common/SelectedFiltersV2";
import TagModal from "Components/Common/TagModal";
import DiscardModal from "Components/DiscardModal";
import ProspectFilterButton from "Components/Prospect/ProspectFilterButton";
import ProspectsTable from "Components/Prospect/ProspectsTable";
import ReferralDetailsModal from "Components/Refer/ReferralDetailsModal";
import SearchBar from "Components/SearchBar";
import StepBadge from "Components/StepBadge";
import { useFilters, useSelectedFilters } from "Hooks/useFilters";
import { useUsersByRole } from "Hooks/useUsers";
import FileService from "Services/FileService";
import S3Service from "Services/S3Service";
import {
  API,
  applicationProcessStatus,
  candidateStatus,
  checklistTypes,
  EMAIL_TEMPLATES_TYPES,
  kanbanColumns,
  MENU_PROSPECT_PROCESS,
  PROCESSES,
  prospectStatus,
} from "constants.js";
import {
  encodeItemsInArray,
  fetcher,
  getApplicationProcessStepName,
  getDocumentByType,
  getFormattedLocalDate,
  getStepFromCandidateStatus,
  removeDuplicates,
  removeElementInArray,
  sendRequest,
} from "utils.js";

import styles from "Screens/styles/ListProspects.module.css";

const DEFAULT_QUERY_PARAMS = {
  limit: 50,
  offset: 1,
  sort: "createdAt:desc",
  company: [],
  recruiter: [],
  position: [],
  status: ["ACTIVE"],
};

const ListProspects = () => {
  const [query, setQuery] = useState(DEFAULT_QUERY_PARAMS);
  const [showMoveToKanbanModal, setShowMoveToKanbanModal] = useState(false);
  const [showDiscardModal, setShowDiscarModal] = useState(false);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showReferralDetailsModal, setShowReferralDetailsModal] =
    useState(false);
  const [moveKanbanModalData, setMoveKanbanModalData] = useState({});
  const [rejectProcessData, setRejectProcessData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSavingMoveToKanban, setIsSavingMoveToKanban] = useState(false);

  const { keycloak } = useKeycloak();
  const { users: recruiters } = useUsersByRole({
    role: "activeRecruiter",
    enabled: true,
  });
  const { filters, setFilters, removeFilter } = useFilters({
    status: [{ value: "ACTIVE", label: "Active", type: "status" }],
  });
  const { selectedFilters } = useSelectedFilters(filters);

  const {
    data: prospects,
    isValidating: loadingProspects,
    mutate: getProspects,
  } = useSWR(
    [API.PROSPECT_PROCESS, query],
    (path) => fetcher(path, keycloak.token),
    {
      revalidateOnFocus: false,
    }
  );

  const { data: companies } = useSWR(
    [API.COMPANIES],
    (path) => fetcher(path, keycloak.token),
    {
      revalidateOnFocus: false,
    }
  );

  const { data: positions } = useSWR(
    [API.JOBS],
    (path) => fetcher(path, keycloak.token),
    {
      revalidateOnFocus: false,
    }
  );

  const { trigger: getCandidate, data: candidate } = useSWRMutation(
    API.CANDIDATE,
    (path, { arg }) =>
      sendRequest(`${path}/${arg.candidateId}`, {
        ...arg,
        accessToken: keycloak.token,
      })
  );

  const { trigger: updateProspectProcess } = useSWRMutation(
    API.PROSPECT_PROCESS,
    (path, { arg }) =>
      sendRequest(`${path}/${arg.prospect}`, {
        ...arg,
        method: "PUT",
        accessToken: keycloak.token,
      })
  );

  const {
    trigger: cancelProspectProcess,
    isMutating: loadingCancelProspectProcess,
  } = useSWRMutation(API.PROSPECT_PROCESS, (path, { arg }) =>
    sendRequest(`${path}/${arg.prospectId}/cancel`, {
      ...arg,
      method: "PUT",
      accessToken: keycloak.token,
    })
  );

  const { trigger: getFileDownloadUrl } = useSWRMutation(
    API.FILE_DOWNLOAD_URL,
    (path, { arg }) =>
      sendRequest(path, {
        ...arg,
        method: "POST",
        accessToken: keycloak.token,
      })
  );

  const { trigger: getUploadFileUrl } = useSWRMutation(
    API.CANDIDATE,
    (path, { arg }) =>
      sendRequest(`${path}/${arg.candidateId}/document`, {
        ...arg,
        method: "POST",
        accessToken: keycloak.token,
      })
  );

  const { trigger: deleteDocument } = useSWRMutation(
    API.CANDIDATE,
    (path, { arg }) =>
      sendRequest(`${path}/${arg.candidateId}/document/${arg.documentId}`, {
        ...arg,
        method: "DELETE",
        accessToken: keycloak.token,
      })
  );

  const { trigger: confirmUploadDocument } = useSWRMutation(
    API.CANDIDATE,
    (path, { arg }) =>
      sendRequest(`${path}/${arg.candidateId}/document`, {
        ...arg,
        method: "PUT",
        accessToken: keycloak.token,
      })
  );

  const { trigger: getDocuments } = useSWRMutation(
    API.CANDIDATE,
    (path, { arg }) =>
      sendRequest(`${path}/${arg.candidateId}/document`, {
        ...arg,
        accessToken: keycloak.token,
      })
  );

  const { trigger: cancelApplicationProcess } = useSWRMutation(
    API.APPLICATION_PROCESS,
    (path, { arg }) =>
      sendRequest(`${path}/${arg.applicationProcessId}/cancel`, {
        ...arg,
        method: "PUT",
        accessToken: keycloak.token,
      })
  );

  const { trigger: reactivateCandidate } = useSWRMutation(
    API.CANDIDATE,
    (path, { arg }) =>
      sendRequest(`${path}/${arg.candidateId}/reactivate`, {
        ...arg,
        method: "PUT",
        accessToken: keycloak.token,
      })
  );

  const { trigger: createApplicationProcess } = useSWRMutation(
    API.APPLICATION_PROCESS,
    (path, { arg }) =>
      sendRequest(path, {
        ...arg,
        method: "POST",
        accessToken: keycloak.token,
      })
  );

  const { trigger: getReferral, data: referral } = useSWRMutation(
    API.REFERRAL,
    (path, { arg }) =>
      sendRequest(`${path}/${arg.referralId}`, {
        ...arg,
        accessToken: keycloak.token,
      })
  );

  const updateProspectsWithRecruiter = ({
    prospects,
    prospectId,
    recruiter,
    query,
  }) => {
    const updatedProspects = prospects.data
      ?.map((prospect) => {
        if (prospect._id === prospectId) {
          return {
            ...prospect,
            recruiterId: recruiter._id,
            recruiterName: recruiter.name,
          };
        }
        return prospect;
      })
      .filter((prospect) =>
        query.recruiter?.length > 0
          ? query.recruiter?.includes(prospect.recruiterId)
          : true
      );

    const total =
      prospects?.headers?.total -
      (prospects?.data?.length - updatedProspects?.length);

    return {
      data: updatedProspects,
      headers: {
        ...prospects.headers,
        total,
      },
    };
  };

  const removeProspect = (prospects, prospectId) => {
    const updatedProspects = removeElementInArray(
      prospects?.data,
      (prospects) => prospects._id === prospectId
    );

    return {
      data: updatedProspects,
      headers: {
        ...prospects.headers,
        total: prospects?.headers?.total - 1,
      },
    };
  };

  const deleteExistingResume = async (candidateId) => {
    const documents = await getDocuments({
      candidateId,
    });

    const resume = getDocumentByType(documents, checklistTypes.RESUME.key);
    if (!resume) {
      return;
    }

    await deleteDocument({
      candidateId,
      documentId: resume._id,
    });
  };

  const getDocumentBlobData = async (
    resumeKey,
    resumeName,
    resumeContentType
  ) => {
    const downloadDocumentUrl = await getFileDownloadUrl({
      body: {
        key: resumeKey,
        fileName: resumeName,
      },
    });

    const fileBlobData = await FileService.getBlobFile(downloadDocumentUrl.url);
    const fileBlob = new File([fileBlobData], resumeName, {
      type: resumeContentType,
    });

    return fileBlob;
  };

  const updateResume = async (prospect) => {
    const updatedProspect = { ...prospect };
    const document = {
      metadata: {
        fileName: updatedProspect.resumeName,
        contentType: updatedProspect.resumeContentType,
        checklistType: checklistTypes.RESUME.key,
        key: "",
      },
    };

    await deleteExistingResume(updatedProspect.candidateId);

    const documentBlob = await getDocumentBlobData(
      updatedProspect.resumeKey,
      updatedProspect.resumeName,
      updatedProspect.resumeContentType
    );
    document.metadata.size = documentBlob.size;
    document.file = documentBlob;

    const uploadNewResumeData = await getUploadFileUrl({
      candidateId: updatedProspect.candidateId,
      body: document.metadata,
    });

    await S3Service.uploadFileToS3(uploadNewResumeData.url, document.file);
    updatedProspect.resumeKey = uploadNewResumeData.key;
    document.metadata.key = uploadNewResumeData.key;

    const confirmUploadDocumentData = await confirmUploadDocument({
      candidateId: updatedProspect.candidateId,
      body: document.metadata,
    });

    const newResume = getDocumentByType(
      confirmUploadDocumentData,
      checklistTypes.RESUME.key
    );

    updatedProspect.resume = {
      _id: newResume._id,
      fileName: newResume.fileName,
      contentType: newResume.contentType,
      size: newResume.size,
    };

    return updatedProspect;
  };

  const handleChangePage = (page) => {
    if (query.offset !== page) {
      setQuery((prev) => ({
        ...prev,
        offset: page,
      }));
    }
  };

  const handleChangeSort = async ({ columnName }, sortDirection) => {
    setQuery((prev) => ({
      ...prev,
      sort: `${columnName}:${sortDirection}`,
      offset: DEFAULT_QUERY_PARAMS.offset,
    }));
  };

  const handleOnSelectRecruiter = async (recruiter, prospectId) => {
    updateProspectProcess({
      prospect: prospectId,
      body: { recruiterId: recruiter._id },
    });

    await getProspects(
      (prospects) =>
        updateProspectsWithRecruiter({
          prospects,
          prospectId,
          recruiter,
          query,
        }),
      { revalidate: false }
    );
  };

  const handleOnClickDownloadDocument = async (file, inline) => {
    const response = await getFileDownloadUrl({
      body: file,
    });

    if (!response?.url) {
      return;
    }

    if (inline) {
      window.open(response.url, "_blank");
    } else {
      const fileBlobData = await FileService.getBlobFile(response.url);

      saveAs(new Blob([fileBlobData]), file.fileName);
    }
  };

  const handleUpdateQueryFilters = (newFilters) => {
    const recruiter = newFilters.recruiter?.map((recruiter) => recruiter.value);
    const company = newFilters.company?.map((company) => company.label);
    const position = newFilters.position?.map((position) => position.label);
    const sourceFlow = newFilters.sourceFlow?.map(
      (sourceFlow) => sourceFlow.value
    );
    let status = newFilters.status?.map((status) => status.value);

    if (!status?.length) {
      status = prospectStatus.map((status) => status.value);
    }

    setQuery((prev) => ({
      ...prev,
      offset: DEFAULT_QUERY_PARAMS.offset,
      company: encodeItemsInArray(company),
      recruiter: encodeItemsInArray(recruiter),
      position: encodeItemsInArray(position),
      status: encodeItemsInArray(status),
      sourceFlow: encodeItemsInArray(sourceFlow),
    }));
  };

  const handleDeleteBadge = (option) => {
    const newFilters = removeFilter(option);

    handleUpdateQueryFilters(newFilters);
  };

  const handleApplyFilters = (filters) => {
    setFilters(filters);

    handleUpdateQueryFilters(filters);
  };

  const handleSearch = async ({ search }) => {
    search = search || " ";

    setQuery((prev) => ({
      ...prev,
      search,
      offset: DEFAULT_QUERY_PARAMS.page,
    }));
  };

  const handleCreateApplicationProcess = async (candidate, prospect) => {
    try {
      setIsSavingMoveToKanban(true);

      if (!candidate.isActive) {
        await reactivateCandidate({
          candidateId: candidate._id,
        });
      }

      const application = {
        candidateId: candidate._id,
        step: kanbanColumns.PIPELINE.key,
        prospectProcessId: prospect._id,
        recruiterId: prospect.recruiterId,
        positionId: prospect.positionId || null,
      };

      if (prospect.resumeKey) {
        const updatedProspect = await updateResume(prospect);

        application.resumeKey = updatedProspect.resumeKey;
        application.resume = updatedProspect.resume;
      }

      await createApplicationProcess({
        body: application,
      });

      await getProspects(
        (prospects) => removeProspect(prospects, prospect._id),
        {
          revalidate: false,
        }
      );

      toast.success("Success! Prospect moved to Kanban.");

      setShowMoveToKanbanModal(false);
      setMoveKanbanModalData({});
    } finally {
      setIsSavingMoveToKanban(false);
    }
  };

  const handleOnClickPreviewReferralDetail = async (sourceFlowId) => {
    try {
      setIsLoading(true);

      await getReferral({
        referralId: sourceFlowId,
      });

      setShowReferralDetailsModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getMoveToKanbanModalData = ({
    status,
    name,
    statusUpdatedAt,
    applicationProcess,
    prospect,
  }) => {
    const step = getStepFromCandidateStatus(status);
    const modalData = {
      hasApplication: false,
      statusMessage: `This prospect is in a ${getApplicationProcessStepName(step)} status.`,
      statusBadge: step,
      name,
      lastUpdate: getFormattedLocalDate(statusUpdatedAt, "MMMM D, YYYY"),
      prospect,
    };

    if (
      applicationProcess &&
      applicationProcess.status !== applicationProcessStatus.cancelled
    ) {
      if (applicationProcess.status === applicationProcessStatus.active) {
        modalData.statusMessage =
          "This prospect is in a current hiring process.";
      }
      modalData.hasApplication = true;
      modalData.position = applicationProcess.position?.title ?? "";
      modalData.company = applicationProcess.position?.company?.name ?? "";
      modalData.recruiterName = applicationProcess.recruiterName;
    }

    return modalData;
  };

  const handleConfirmMoveToKanban = async (candidate, prospect) => {
    if (
      candidate.applicationProcess?.status ===
      ApplicationProcessStatusEnum.Active
    ) {
      await cancelApplicationProcess({
        applicationProcessId: candidate.applicationProcess._id,
        body: {
          requiresOwnership: false,
        },
      });
    }

    await handleCreateApplicationProcess(candidate, prospect);
  };

  const handleMoveToKanban = async (prospect) => {
    if (!prospect.recruiterId?.length) {
      toast.error("The assign TA is required to move prospect to Kanban.");

      return;
    }

    try {
      setIsLoading(true);

      setMoveKanbanModalData({});

      const candidate = await getCandidate({
        candidateId: prospect.candidateId,
      });

      if (candidate.status === candidateStatus.LEAD.key) {
        await handleCreateApplicationProcess(candidate, prospect);

        return;
      }

      const modalData = getMoveToKanbanModalData({
        ...candidate,
        prospect,
      });

      setMoveKanbanModalData(modalData);
      setShowMoveToKanbanModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleShowTagsModal = async (prospect) => {
    try {
      setIsLoading(true);

      await getCandidate({
        candidateId: prospect.candidateId,
      });

      setShowTagsModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleShowDiscardModal = (prospect) => {
    let emailTemplateType;

    switch (prospect.sourceFlow) {
      case "APPLICATION":
        emailTemplateType =
          EMAIL_TEMPLATES_TYPES.PROSPECT_PROCESS_DISCARDED_APPLICATION;
        break;

      case "REFERRAL":
        emailTemplateType =
          EMAIL_TEMPLATES_TYPES.PROSPECT_PROCESS_DISCARDED_REFERRAL;
        break;

      default:
        emailTemplateType = "";
    }

    setRejectProcessData({
      prospectId: prospect._id,
      candidateName: prospect.name,
      emailTemplateType,
    });
    setShowDiscarModal(true);
  };

  const handleClickSubmitDiscard = async (formValues) => {
    const discardData = {
      ...formValues,
    };

    await cancelProspectProcess({
      prospectId: rejectProcessData.prospectId,
      body: discardData,
    });

    await getProspects(
      (prospects) => removeProspect(prospects, rejectProcessData.prospectId),
      {
        revalidate: false,
      }
    );

    setShowDiscarModal(false);
    setRejectProcessData({});
    toast.success("Success! The prospect process has been rejected.");
  };

  const handleSelectMenuOption = async (option, prospect) => {
    switch (option) {
      case MENU_PROSPECT_PROCESS.MOVE_TO_KANBAN.eventKey:
        await handleMoveToKanban(prospect);
        break;
      case MENU_PROSPECT_PROCESS.TAGS.eventKey:
        await handleShowTagsModal(prospect);
        break;
      case MENU_PROSPECT_PROCESS.REJECT.eventKey:
        handleShowDiscardModal(prospect);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <FullPageOverlay show={isLoading} isLoading />
      <SearchBar disabled={loadingProspects} onSearch={handleSearch} />

      <h1 className="ScreenTitle">Prospects</h1>
      <div className={styles.Filters}>
        <div>
          <SelectedFiltersV2
            filterValues={selectedFilters}
            icon={faTimes}
            iconPosition="right"
            onClickDeleteBadge={handleDeleteBadge}
          />
        </div>
        <ProspectFilterButton
          filterValues={filters}
          companies={companies?.data}
          positions={removeDuplicates(positions?.data, "title", true)}
          recruiters={recruiters}
          loading={loadingProspects}
          onApplyFilters={handleApplyFilters}
        />
      </div>
      <Card className="Card">
        <Card.Body>
          <ProspectsTable
            prospects={prospects?.data}
            recruiters={recruiters}
            currentPage={query.offset}
            loading={loadingProspects}
            totalProspects={parseInt(prospects?.headers?.total)}
            paginationPerPage={DEFAULT_QUERY_PARAMS.limit}
            onChangePage={handleChangePage}
            onChangeSort={handleChangeSort}
            onSelectRecruiter={handleOnSelectRecruiter}
            onClickDownloadDocument={handleOnClickDownloadDocument}
            onClickPreviewDocument={(file) =>
              handleOnClickDownloadDocument(file, true)
            }
            onClickPreviewReferralDetail={handleOnClickPreviewReferralDetail}
            onClickSelectedMenuOption={handleSelectMenuOption}
          />
        </Card.Body>
      </Card>
      <ConfirmModal
        title="Move to Kanban"
        cancelButtonText="Cancel"
        confirmButtonText="Move to Kanban"
        show={showMoveToKanbanModal}
        isLoading={isSavingMoveToKanban}
        backdrop="static"
        onClose={() => setShowMoveToKanbanModal(false)}
        onConfirm={() =>
          handleConfirmMoveToKanban(candidate, moveKanbanModalData.prospect)
        }
      >
        <div className={styles.MoveKanbanModalContent}>
          <p className={styles.StatusMessage}>
            {moveKanbanModalData.statusMessage}
          </p>
          <StepBadge step={moveKanbanModalData.statusBadge} />
          <p className={styles.CandidateInfo}>
            <span>{moveKanbanModalData.name}</span>
            <br />
            {moveKanbanModalData.hasApplication &&
              `${moveKanbanModalData.company} - ${moveKanbanModalData.position}`}
          </p>
          <p className={styles.ApplicationInfo}>
            {moveKanbanModalData.hasApplication && (
              <>
                TA in charge: {moveKanbanModalData.recruiterName}
                <br />
              </>
            )}
            <span>Last update: {moveKanbanModalData.lastUpdate}</span>
          </p>
        </div>
      </ConfirmModal>
      <TagModal
        show={showTagsModal}
        candidateId={candidate?._id}
        defaultTags={candidate?.tags ?? []}
        onClose={() => setShowTagsModal(false)}
        onSaved={() => toast.success("Success! Tags added to your prospect.")}
      />
      <DiscardModal
        show={showDiscardModal}
        isLoading={loadingCancelProspectProcess}
        process={PROCESSES.PROSPECT}
        emailTemplateType={rejectProcessData.emailTemplateType}
        candidateName={rejectProcessData.candidateName}
        onSubmit={handleClickSubmitDiscard}
        onClose={() => setShowDiscarModal(false)}
      />
      <ReferralDetailsModal
        show={showReferralDetailsModal}
        referral={referral}
        onClose={() => setShowReferralDetailsModal(false)}
      />
    </>
  );
};

export default ListProspects;
