import { useKeycloak } from "@react-keycloak/web";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

import { API } from "constants.js";
import { sendRequest, sortByProperty } from "utils.js";

export const useCandidateAchievements = () => {
  const { keycloak } = useKeycloak();

  const candidateId = keycloak?.tokenParsed?.candidateId;

  const {
    data: candidateAchievements,
    isLoading,
    mutate,
  } = useSWR(
    `${API.CANDIDATE}/${candidateId}/achievement`,
    (path, options) =>
      sendRequest(path, {
        ...options,
        accessToken: keycloak.token,
      }),
    {
      revalidateOnFocus: false,
      fallbackData: [],
      onSuccess: (data) => sortByProperty(data, "earnedDate", "desc"),
    }
  );

  return {
    candidateAchievements,
    isLoading,
    mutate,
  };
};

export const useCandidateAchievementCertificate = () => {
  const { keycloak } = useKeycloak();

  const candidateId = keycloak?.tokenParsed?.candidateId;

  const { trigger: getCertificate, isMutating: isDownloadingCertificate } =
    useSWRMutation(API.CANDIDATE, (path, { arg }) =>
      sendRequest(
        `${path}/${candidateId}/achievement/${arg.achievementId}/certificate`,
        {
          ...arg,
          responseType: "arraybuffer",
          accessToken: keycloak.token,
        }
      )
    );

  return {
    getCertificate,
    isDownloadingCertificate,
  };
};

export const usePublicCandidateAchievement = (candidateId, achievementId) => {
  const {
    data: candidateAchievement,
    isLoading,
    mutate,
  } = useSWR(
    `${API.PUBLIC_CANDIDATE}/${candidateId}/achievement/${achievementId}`,
    (path, options) =>
      sendRequest(path, {
        ...options,
      }),
    {
      revalidateOnFocus: false,
      fallbackData: {},
    }
  );

  return {
    candidateAchievement,
    isLoading,
    mutate,
  };
};
