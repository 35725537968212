import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { toast } from "react-toastify";
import { ConfirmModal, useSearchableList } from "@itj-micoach/micoach-common";

import AchievementAddEditModal from "Components/Admin/Achievements/AchievementAddEditModal";
import AchievementPreviewModal from "Components/Admin/Achievements/AchievementPreviewModal";
import AchievementTable from "Components/Admin/Achievements/AchievementTable";
import GoBackButton from "Components/GoBackButton";
import SearchBar from "Components/SearchBar";
import { useAchievements } from "Hooks/useAchievements";
import { ACHIEVEMENT_TABLE_MENU, SUBMIT_TYPES } from "constants.js";

const SEARCH_KEY = "name";

const Achievements = () => {
  const [showAchievementAddEditModal, setShowAchievementAddEditModal] =
    useState(false);
  const [showAchievementPreviewModal, setShowAchievementPreviewModal] =
    useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedAchievement, setSelectedAchievement] = useState(null);

  const {
    achievement,
    achievements,
    getAchievement,
    getAchivements,
    sendAchievement,
    deleteAchievement,
    isLoadingAchivements,
    isDeleting,
    isSendingAchievement,
  } = useAchievements();

  const { filteredItems, setSearchTerm } = useSearchableList({
    items: achievements,
    searchKey: SEARCH_KEY,
    isLoadingAchivements,
  });

  const handleSelectMenuOption = async (option, selectedAchievement) => {
    switch (option) {
      case ACHIEVEMENT_TABLE_MENU.EDIT.eventKey: {
        const currentAchievement = await getAchievement({
          achievementId: selectedAchievement._id,
        });

        setSelectedAchievement(currentAchievement);
        setShowAchievementAddEditModal(true);
        break;
      }
      case ACHIEVEMENT_TABLE_MENU.DELETE.eventKey:
        setSelectedAchievement(selectedAchievement);
        setShowConfirmModal(true);
        break;
      default:
        break;
    }
  };

  const handleSavedAchievement = async (achievementId, submitType) => {
    await getAchivements();

    if (submitType === SUBMIT_TYPES.PREVIEW) {
      await getAchievement({
        achievementId,
      });
      setShowAchievementAddEditModal(false);
      setShowAchievementPreviewModal(true);
    }
  };

  const handleClickSendAchievement = async (achievementId) => {
    await sendAchievement({ achievementId });
    await getAchivements();
    setShowAchievementPreviewModal(false);
    toast.success("Success! The achievement has been sent.");
  };

  const handleClickCreate = () => {
    setSelectedAchievement(null);
    setShowAchievementAddEditModal(true);
  };

  const handleClickConfirmDelete = async () => {
    await deleteAchievement({
      achievementId: selectedAchievement._id,
    });
    await getAchivements();

    setSelectedAchievement(null);
    setShowConfirmModal(false);
  };

  const handleClickCloseConfirmDelete = () => {
    setSelectedAchievement(null);
    setShowConfirmModal(false);
  };

  useEffect(() => {
    getAchivements();
  }, [getAchivements]);

  return (
    <>
      <GoBackButton label="Admin" path="/admin" />

      <Row>
        <Col>
          <h1 className="ScreenTitle">Achievements</h1>
        </Col>
        <Col className="text-end">
          <Button
            variant="secondary"
            className="AddButton"
            onClick={handleClickCreate}
          >
            Create Achievement
          </Button>
        </Col>
      </Row>

      <SearchBar onSearch={({ search }) => setSearchTerm(search?.trim())} />

      <Card className="Card">
        <Card.Body>
          <AchievementTable
            achievements={filteredItems}
            loading={isLoadingAchivements}
            onClickSelectedMenuOption={handleSelectMenuOption}
          />
        </Card.Body>
      </Card>

      <AchievementAddEditModal
        show={showAchievementAddEditModal}
        achievement={selectedAchievement}
        onSaved={handleSavedAchievement}
        onClose={() => setShowAchievementAddEditModal(false)}
      />

      <AchievementPreviewModal
        show={showAchievementPreviewModal}
        achievement={achievement}
        isSending={isSendingAchievement}
        onClickSend={handleClickSendAchievement}
        onClose={() => setShowAchievementPreviewModal(false)}
      />

      <ConfirmModal
        title="Delete Achievement"
        confirmButtonText="Yes, delete"
        show={showConfirmModal}
        centered
        onConfirm={handleClickConfirmDelete}
        onClose={handleClickCloseConfirmDelete}
        isLoading={isDeleting}
      >
        Are you sure you want to delete the Achievement{" "}
        <strong>{selectedAchievement?.name}</strong>?
        <div className="mt-3"> This action cannot be undone.</div>
      </ConfirmModal>
    </>
  );
};

export default Achievements;
